import React, { useState } from "react";
import { ReactComponent as AscendingIcon } from "../../assets/icons/ascending_icon.svg";
import { ReactComponent as DescendingIcon } from "../../assets/icons/descending_icon.svg";
import { ReactComponent as DefaultIcon } from "../../assets/icons/default_icon.svg";
import { ReactComponent as SuccessCheck } from "../../assets/icons/successCheck.svg";
import "./paymentTable.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const paymentStatus = [
  {
    id: "fullypaid",
    label: "FULLY PAID",
  },
  {
    id: "partiallypaid",
    label: "PARTIALLY PAID",
  },
  {
    id: "billedtoclient",
    label: "BILLED TO CLIENT",
  },
];

export default function Index(props) {
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleChange = (event) => {
    props.setSelectedStatus(event.target.value);
  };

  const handleSortClick = (order, columnId) => {
    if (order === props.sortOrder) {
      props.setSortBy(columnId.toUpperCase());
      props.setSortOrder(
        order == "ASC" ? "DESC" : order == "DESC" ? null : "ASC"
      );
    } else {
      props.setSortBy(columnId.toUpperCase());
      props.setSortOrder(order);
    }
  };

  const getSortIcon = (column) => {
    if (column === props.sortBy.toLowerCase() && props.sortOrder === "DESC") {
      return <DescendingIcon />;
    } else if (
      column === props.sortBy.toLowerCase() &&
      props.sortOrder === "ASC"
    ) {
      return <AscendingIcon />;
    } else {
      return <DefaultIcon />;
    }
  };

  const editBudgetStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  return (
    <>
      <Paper
        sx={{
          maxHeight: "75vh",
          width: "98%",
          overflow: "hidden",
          margin: "10px",
        }}
      >
        <TableContainer
          sx={{
            borderCollapse: "collapse",
            maxHeight: "25vh",
            fontStyle: "Open Sans,",
            fontSize: "1rem",
            width: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            style={props.tableStyle ? props.tableStyle : {}}
            className="payment-table"
          >
            <TableHead>
              <TableRow>
                {props.columns.map((column, index) => (
                  <TableCell
                    key={props.normalData ? column.id : index}
                    align={column.align}
                    className="tableHeadStyle"
                    style={{
                      backgroundColor: "#c5c5c5",
                      width: column.id === "nameFile" ? "80%" : "",
                    }}
                  >
                    {props.title == "transactionDetails" &&
                    (column.id === "date" || column.id === "amount") ? (
                      <>
                        <div className="coloumn_label">{column.label}</div>
                        <div
                          className="sorting-p-div"
                          onClick={() => handleSortClick("ASC", column.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {getSortIcon(column.id)}
                        </div>
                      </>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rows.length > 0 ? (
                props.rows.map((row, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                    style={{ cursor: "pointer" }}
                    className="tableDataRowStyle"
                    onClick={() => {
                      if (
                        props.title === "itemDetails" ||
                        props.title === "invoiceDetails" ||
                        props.title === "transactionDetails"
                      ) {
                        return true;
                      } else {
                        props.handleHistoryTableClick(row.id, row.nameFile);
                      }
                    }}
                  >
                    {props.columns.map((column, ind) => {
                      let value = row[column.id];
                      return (
                        <TableCell
                          key={ind}
                          align={column.align}
                          className="tableCellStyle"
                        >
                          {props.normalData ? (
                            column.id === "paymentStatus" &&
                            hoveredRow == index ? (
                              <Select
                                value={value.toLowerCase().replace(/\s/g, "")}
                                onChange={(event) =>
                                  props.handlePaymentStatusChange(event, row.id)
                                }
                              >
                                {paymentStatus.map((status) => (
                                  <MenuItem key={status.id} value={status.id}>
                                    {status.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : column.id === "grandTotal" &&
                              hoveredRow == index ? (
                              <>
                                <span>{value}</span>
                                <Checkbox
                                  size="small"
                                  onChange={(event) =>
                                    props.handleInvoiceCheck(event, row.id)
                                  }
                                  checked={props.invoiceID === row.id}
                                />
                              </>
                            ) : (props.title
                                ? props.title !== "invoiceDetails"
                                : true) &&
                              props.title !== "unmappedTransactions" &&
                              column.id === "transactionDetails" &&
                              (hoveredRow == index ||
                                props.paymentDetailID === row.id) ? (
                              <div style={editBudgetStyle}>
                                <span>{value}</span>
                                {row.mapped && row.mapped === 2 ? (
                                  <SuccessCheck />
                                ) : (
                                  <Checkbox
                                    size="small"
                                    onChange={(event) =>
                                      props.handlePaymentDetailCheck(
                                        event,
                                        row.id,
                                        row
                                      )
                                    }
                                    checked={props.paymentDetailID === row.id}
                                  />
                                )}
                              </div>
                            ) : column.format && typeof value === "number" ? (
                              column.format(value)
                            ) : (
                              value
                            )
                          ) : (
                            row.name
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={props.columns.length}
                    style={{ textAlign: "center", width: "full" }}
                  >
                    NO DATA AVAILABLE
                  </TableCell>
                </TableRow>
              )}
              {/* {props.paymentStatusID == 3 &&
                props.title === "invoiceDetails" && (
                  <TableRow>
                    <TableCell className="tableCellStyle">
                      {props.fullyPaidRows.date}
                    </TableCell>
                    <TableCell className="tableCellStyle">
                      {props.fullyPaidRows.amount}
                    </TableCell>
                    <TableCell className="tableCellStyle">{`${props.fullyPaidRows.transactionDetails}`}</TableCell>
                  </TableRow>
                )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={props.totalCount}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                onPageChange={(event, newPage) => props.handleChangePage(newPage)}
                colSpan={3}
                onRowsPerPageChange={(event) => props.handleChangeRowsPerPage(+event.target.value)}
                sx={{
                    position: "fixed",
                    right: "20px",
                    bottom: "7px",
                    padding: "0px",
                    backgroundColor: "#ffffff",
                }}
            /> */}
    </>
  );
}
