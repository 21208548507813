import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import '../../assets/styles/deleteContent.css';
import { removeInvoiceAPI } from '../../utils/api-helper';
import qs from 'qs';
import { useNavigate } from "react-router-dom";

export default function DeleteInvoice(props) {
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    const row = { id: id }
    const navigate = useNavigate();

    return (
        <>
            
        </>
    )
}
