import React, { useState, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { TablePagination, Checkbox } from '@mui/material';
import { ReactComponent as AscendingIcon } from "../../assets/icons/ascending_icon.svg";
import { ReactComponent as DescendingIcon } from "../../assets/icons/descending_icon.svg";
import { ReactComponent as DefaultIcon } from "../../assets/icons/default_icon.svg";
import './pendingTable.css';

export default function PendingInvoiceTable(props) {
  const [hoveredRowId, setHoveredRowId] = useState(null);

  const handleSortClick = useCallback((order, columnId) => {
    if (typeof props.setsortInvoiceData === 'function' && typeof props.setSortOrder === 'function') {
      if (order === props.sortOrder) {
        props.setsortInvoiceData(columnId);
        props.setSortOrder(order === "ASC" ? "DESC" : order === "DESC" ? null : "ASC");
      } else {
        props.setsortInvoiceData(columnId);
        props.setSortOrder(order);
      }
    }
  }, [props.sortOrder, props.setsortInvoiceData, props.setSortOrder]);

  const getSortIcon = useCallback((column) => {
    if (column === props.sortInvoiceData && props.sortOrder === "ASC") {
      return <DescendingIcon />;
    } else if (column === props.sortInvoiceData && props.sortOrder === "DESC") {
      return <AscendingIcon />;
    } else {
      return <DefaultIcon />;
    }
  }, [props.sortInvoiceData, props.sortOrder]);

  const shouldVisible = useCallback((row) => {
    return props.paymentDetailID !== null
      ? props.invoiceID?.find((checkbox) => checkbox.id === row.id)?.checked
      : props.statusInvoiceID === row.id;
  }, [props.paymentDetailID, props.invoiceID, props.statusInvoiceID]);

  const columnDefs = useMemo(() => 
    // console.log('props columns', props.columns)
    (props.columns || []).map(column => ({
      field: column.id,
      headerName: column.label,
      sortable: true,
      filter: true,
      flex: 1,
      headerComponent: (params) => {
        console.log('param name---', params)
       return( <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>{params.displayName}</span>
          {(props.tableTitle === "listOfInvoices" || column.id === "date" || column.id === "amount") && (
            <div onClick={() => handleSortClick("ASC", column.id)} style={{ cursor: "pointer" }}>
              {getSortIcon(column.id)}
            </div>
          )}
        </div>)
      }
      ,
      cellRenderer: (params) => {
        if (column.id === "paymentStatus") {
          return params.value ? params.value : "--";
        } else if (column.id === "clientName") {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{params.value ? params.value : "--"}</span>
              {/* <div style={{
                opacity: props.invoiceID?.some(item => item.id === params.data.id) || hoveredRowId === params.data.id ? 1 : 0,
                pointerEvents: props.invoiceID?.some(item => item.id === params.data.id) || hoveredRowId === params.data.id ? "auto" : "none",
              }}>
                <Checkbox
                  onChange={(e) => typeof props.handleInvoiceCheck === 'function' && props.handleInvoiceCheck(e.target.checked, params.data)}
                  checked={props.invoiceID?.some(item => item.id === params.data.id) || false}
                />
              </div> */}
            </div>
          );
        } else if (column.id === "transactionDetails") {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span>{params.value}</span>
              {shouldVisible(params.data) && (
                <Checkbox
                  size="small"
                  onChange={(event) => typeof props.handlePaymentDetailCheck === 'function' && props.handlePaymentDetailCheck(event, params.data.id)}
                  checked={props.paymentDetailID === params.data.id}
                />
              )}
            </div>
          );
        }else if(column.id == "amountDue"){
          return(
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onMouseEnter={() => setHoveredRowId(params.data.id)}
            onMouseLeave={() => setHoveredRowId(null)}>
            {column.format && typeof params.value === "number" ? column.format(params.value) : params.value ? params.value : "--"}
            <div style={{
              opacity: props.invoiceID?.some(item => item.id === params.data.id) || hoveredRowId === params.data.id ? 1 : 0,
              pointerEvents: props.invoiceID?.some(item => item.id === params.data.id) || hoveredRowId === params.data.id ? "auto" : "none",
            }}>
              <Checkbox
                onChange={(e) => typeof props.handleInvoiceCheck === 'function' && props.handleInvoiceCheck(e.target.checked, params.data)}
                checked={props.invoiceID?.some(item => item.id === params.data.id) || false}
              />
            </div>
            </div>
          )

        } else {
          return column.format && typeof params.value === "number" ? column.format(params.value) : params.value ? params.value : "--";
        }
      },
    })),
  [props.columns, props.tableTitle, props.sortInvoiceData, props.sortOrder, props.invoiceID, props.paymentDetailID, hoveredRowId, handleSortClick, getSortIcon, shouldVisible]);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    resizable: true,
  }), []);

  const filteredData = useMemo(() => 
    props.rows ? props.rows.filter((row) =>
      Object.values(row).some(
        (value) =>
          value !== null &&
          value
            .toString()
            .toLowerCase()
            .includes((props.search || '').toLowerCase())
      )
    ) : [],
  [props.rows, props.search]);

  const onRowClicked = useCallback((event) => {
    if (props.isTableEditable) {
      return;
    }
    if (props.tableTitle === "monthlyProjectBudget" || props.navigatedFromDashboard) {
      return;
    }
    if (typeof props.function === 'function') {
      props.function(event.data);
    }
  }, [props.isTableEditable, props.tableTitle, props.navigatedFromDashboard, props.function]);

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <div className="ag-theme-material" style={{ height: 'calc(50vh - 48px)', width: '100%' }}>
        <AgGridReact
          rowData={filteredData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onRowClicked={onRowClicked}
        />
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 20, 100]}
        component="div"
        count={props.totalCount || 0}
        rowsPerPage={props.rowsPerPage || 10}
        page={props.page || 0}
        onPageChange={(event, newPage) => typeof props.handleChangePage === 'function' && props.handleChangePage(newPage)}
        onRowsPerPageChange={(event) => typeof props.handleChangeRowsPerPage === 'function' && props.handleChangeRowsPerPage(+event.target.value)}
        sx={{
          padding: "0px",
          backgroundColor: "#ffffff",
        }}
        className="pagination"
      />
    </div>
  );
}