import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navigation";
import "../../assets/styles/managepages.css";
import { createNullEntriesAPI, getAllInvoiceListAPI } from "../../utils/api-helper";
import GetAllWeeklyInvoice from "./getAllWeeklyInvoice/getAllWeeklyInvoice";
// import CreateInvoice from "./createInvoice";
import FilterInvoice from "./FilterInvoice";
import SortInvoice from "./sortInvoice";
import Footer from "../../components/footer/Footer";
import Settings from "../../components/Settings";
import ViewContent from "../manageinvoice/viewInvoice/index";
import DeleteInvoice from "./deleteInvoice";
import BackButton from "../../components/Buttons/backButton";
import Select from "react-select";
import {
    getAllClientAPI,
    getAllProjectAPI,
    getAllCountryAPI,
    getAllInvoiceType,
    getPaymentStatusAPI,
    getPaymentTermsAPI,
    getAllPaymentModeAPI,
    updateInvoicePaymentStatus,
    getAllProjectListAPI
} from "../../utils/api-helper";
// import InvoiceItemsForm from "./invoiceItems";
// import DeleteItemsForm from "./invoiceItems/deleteInvoiceItem";
import InvoiceAlertPopupMessage from "./InvoiceAlertPopupMessage";
// import UpdateInvoiceNumber from "./UpdateInvoiceNumber";
import Loader from "../../components/Loader";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ErrorPopup from '../../components/ErrorPopup';
export var invoiceItemsDataArray = [];
export var TotalInvoiceAmount = 0;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const resetInvoiceItems = () => {
    invoiceItemsDataArray = [];
}

export default function Weeklyinvoice(props) {
    const navigate = useNavigate();
    const [invoiceTotalAmount, setInvoiceTotalAmount] = useState(0);

    const [isGetAllInvoice, setIsGetAllInvoice] = useState(true);
    const [isAddInvoice, setIsAddInvoice] = useState(false);
    const [isGetInvoiceByid, setIsGetInvoiceByid] = useState(false);
    const [isEditInvoice, setIsEditInvoice] = useState(false);
    const [isMakeCopyInvoice, setIsMakeCopyInvoice] = useState(false);
    const [isFilterInvoice, setIsFilterInvoice] = useState(false);
    const [filterByInvoiceTypeData, setFilterByInvoiceTypeData] = useState("1");
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [isSortInvoice, setIsSortInvoice] = useState(false);
    const [isDeleteInvoice, setIsDeleteInvoice] = useState(false);
    const [isAddInvoiceItems, setIsAddInvoiceItems] = useState(false);
    const [isDeleteInvoiceItem, setIsDeleteInvoiceItem] = useState(false);
    const [isEditInvoiceItem, setIsEditInvoiceItem] = useState(false);
    const [startLoader, setStartLoader] = useState(false);
    const [alertPopupWindow, setAlertPopupWindow] = useState(false);
    const [alertPopupMessage, setAlertPopupMessage] = useState('');
    const [updateInvoiceNumberWindow, setUpdateInvoiceNumberWindow] = useState(false);
    const [getAlertReponse, setAlertReponse] = useState(false);
    const [isFilterSelected, setIsFilterSelected] = useState(false);
    const [activeFilters, setActiveFilters] = useState([]);
    const [invoiceNumber, setinvoiceNumber] = useState("");
    const [clientFirstNameFilter, setClientFirstNameFilter] = useState("");
    const [clientLastNameFilter, setClientLastNameFilter] = useState("");
    const [projectNameFilter, setProjectNameFilter] = useState("");
    const [dueDateFromFilter, setDueDateFromFilter] = useState("");
    const [dueDateToFilter, setDueDateToFilter] = useState("");
    const [invoiceDateFromFilter, setInvoiceDateFromFilter] = useState("");
    const [invoiceDateToFilter, setInvoiceDateToFilter] = useState("");
    const [paidDateFromFilter, setPaidDateFromFilter] = useState("");
    const [paidDateToFilter, setPaidDateToFilter] = useState("");
    const [isActiveFilter, setIsActiveFilter] = useState(false);
    const [isPaymentStatusFilter, setIsPaymentStatusFilter] = useState("");
    const [isPaymentStatusLabel, setIsPaymentStatusLabel] = useState("");
    const [sortInvoiceData, setsortInvoiceData] = useState("PROJECTNAME");

    //for updating status
    const [projectType, setProjectType] = useState("");
    const [projectID, setProjectID] = useState("");
    const [updateStatusBody, setUpdateStatusBody] = useState({});
    const [statusUpdated, setStatusUpdated] = useState(false);
    const [error, setError] = useState(null);
    // weekOptions[weekOptions.length - 1].label
    const [currentWeek, setCurrentWeek] = useState({});
    const [upworkInvoiceGenerated,setUpworkInvoiceGenerated] = useState(false);
    const [isInvoiceGenerated,setisInvoiceGenerated] = useState(false);
    const [showInvoices, setShowInvoices] = useState(false);
    const [response, setResponse] = useState([]);
    const [getAllCLients, setGetAllCLients] = useState();
    const [getAllProjects, setGetAllProjects] = useState();
    const [getAllCountries, setGetAllCountries] = useState();
    const [getPaymentStatus, setGetPaymentStatus] = useState();
    const [getPaymentTerms, setGetPaymentTerms] = useState();
    const [getPaymentMode, setGetPaymentMode] = useState();
    const [invoiceType, setInvoiceType] = useState([]);
    const [pastFourWeeks, setPastFourWeeks] = useState([]);
    const [invoiceResp, setInvoiceResp] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    

    const body = {
        clientFirstName: clientFirstNameFilter,
        clientLastName: clientLastNameFilter,
        projectName: projectNameFilter,
        dueDateFrom: dueDateFromFilter,
        dueDateTo: dueDateToFilter,
        invoiceStartDate: invoiceDateFromFilter,
        invoiceEndDate: invoiceDateToFilter,
        isActive: isActiveFilter,
        isUpworkProject: true,
        paidDateFrom: paidDateFromFilter,
        paidDateTo: paidDateToFilter,
        paymentStatus: isPaymentStatusFilter,
        invoiceNumber: "",
        invoiceType: filterByInvoiceTypeData,
        projectManagerFirstName: "",

    };
   


    function createData(id, value) {
        return { value: id, label: value };
    }

    useEffect(() => {
        // getAllInvoices();
        getPast4WeeksDates();
        // const weeksOptions = getPast4WeeksDates();
        // setPastFourWeeks(weeksOptions);

    }, []);


    function getPast4WeeksDates() {
        const today = new Date();
        const currentDay = today.getDay();
        const daysToMonday = (currentDay + 6) % 7;
        const thisMonday = new Date(today);
        thisMonday.setDate(today.getDate() - daysToMonday);

        const startOfPeriod = new Date(thisMonday);
        startOfPeriod.setDate(thisMonday.getDate() - 4 * 7);

        const weeks = [];

        for (let i = 0; i < 4; i++) {
            const weekStart = new Date(startOfPeriod);
            weekStart.setDate(startOfPeriod.getDate() + i * 7);

            const weekEnd = new Date(weekStart);
            weekEnd.setDate(weekStart.getDate() + 6);

            weeks.push({
                start: weekStart.toISOString().split('T')[0], // Format: YYYY-MM-DD
                end: weekEnd.toISOString().split('T')[0]    // Format: YYYY-MM-DD
            });


        }

        const options = [];

        for (let i = 0; i < weeks.length; i++) {
            let value = `${weeks[i].start}/${weeks[i].end}`
            options.push({ value: value, label: `${weeks[i].start} - ${weeks[i].end}` });
        }

        // setCurrentWeek(options[weeks.length - 1])
        setPastFourWeeks(options);
        setCurrentWeek(options[options.length - 1])
        setInvoiceDateFromFilter(weeks[weeks.length - 1].start);
        setInvoiceDateToFilter(weeks[weeks.length - 1].end)
        // getAllInvoices();
        // return options;
    }




  
    
    useEffect(() => {

        if (invoiceDateFromFilter !== '' && invoiceDateToFilter !== '' && !showInvoices) {
            getAllProjAPICall();
        }else if(invoiceDateFromFilter !== '' && invoiceDateToFilter !== '' && showInvoices){
            getAllUpworkInvoices();
        }

    }, [page, rowsPerPage, invoiceDateFromFilter, showInvoices ]);


    const handleChangePage = (newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (value) => {
        setRowsPerPage(value);
        setPage(0);
    };


    const handleFiltersApplied = (filters) => {
        setActiveFilters(filters);
    };


    function handleInvoiceCheck(event, id) {
        let checked = event.target.checked;
        if (checked) {
            setProjectID(id);
            setUpdateStatusBody(((prevObject) => ({
                ...prevObject,
                id: id,
            })))
        } else {
            setProjectID(null);
            setUpdateStatusBody({});
        }
    }

    function handlePaymentStatusChange(value, id) {
        if (id == projectID) {
            setUpdateStatusBody(((prevObject) => ({
                ...prevObject,
                paymentStatus: value,
            })))
        }
    }


    const handleWeekChange = (selectedOption) => {

        let startEndDates = selectedOption.value.split('/');
        setCurrentWeek(selectedOption);
        setInvoiceDateFromFilter(startEndDates[0])
        setInvoiceDateToFilter(startEndDates[1]);
    };


    function updateInvoiceStatus() {
        setStartLoader(true);
        const body = {
            paymentStatus: updateStatusBody.paymentStatus
        }
        updateInvoicePaymentStatus(updateStatusBody.id, body).then((res) => {
            setStatusUpdated(true);
        }).catch((err) => {
            if (error.response) {
                setError(error.response.data.message)
            } else if (error.request) {
                setError("Something went wrong, try again")
            } else {
                setError("Something went wrong, try again")
            }
        }).finally(() => {
            setStartLoader(false);
            getAllInvoiceAPICall();
            setUpdateStatusBody({});
            setProjectID("")
        })
    }

    const closeErrorPopup = () => {
        setError(null);
        setUpdateStatusBody({});
        setProjectID("");
        setStatusUpdated(false);
    };

    const getPaymentStatusFunction = () => {
        getPaymentStatusAPI()
            .then((res) => {
                setGetPaymentStatus(
                    res?.data?.map((row) => createData(row.id, row.value))
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    }

    const getPaymnetTermsFunction = () => {
        getPaymentTermsAPI()
            .then((res) => {
                setGetPaymentTerms(
                    res?.data.map((row) => createData(row.id, row.value))
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    }
    const getInvoiceTypeFunction = () => {
        getAllInvoiceType()
            .then((res) => {
                setInvoiceType(
                    res?.data.map((row) => createData(row.id, row.value))
                )
            }).catch((err) => {
                // console.log(err);
            });
    }

    const getAllPaymentModeFunction = () => {
        getAllPaymentModeAPI()
            .then((res) => {
                setGetPaymentMode(
                    res?.data.map((row) => createData(row.id, row.value))
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    }

    const getAllProjAPICall = () => {
        const payload = {
            "clientFirstName": "",
            "clientLastName": "",
            "isActive": true,
            "projectManagerFirstName": "",
            "projectManagerLastName": "",
            "projectName": "",
            "projectType": "",
            "projectsStartDateFrom": "",
            "projectsStartDateTo": "",
            "isUpWorkProject": true,
            "upworkStartDate": invoiceDateFromFilter,
            "upworkEndDate": invoiceDateToFilter,
            "projectTypeId": 2
          }
        setStartLoader(true);
        getAllProjectListAPI(payload, sortInvoiceData, page, rowsPerPage)  //sortInvoiceData is for sorting projects
            .then((res) => {
                createNullEntries();
                setResponse(res?.data?.list);
                setTotalCount(res?.data.totalCount);
                let isAllInvoiceGenerated = res?.data?.list.every(item => item.invoiceGenerated === true);
                setUpworkInvoiceGenerated(isAllInvoiceGenerated);
                let isInvoiceGenerated = res?.data?.list.some(item => item.invoiceGenerated === true);
                setisInvoiceGenerated(isInvoiceGenerated);
                setStartLoader(false);

            })
            .catch((err) => {
                // console.log(err);
                setStartLoader(false);
            });
    };

    function createNullEntries(){
        const body = [{
            startDate : invoiceDateFromFilter,
            endDate : invoiceDateToFilter
        }]

        createNullEntriesAPI(body).then((resp)=>{console.log('Null entries successflly edited')},
        (err)=>console.log(err));
    }

    function getAllUpworkInvoices() {
        setStartLoader(true);
        const body = {
            clientFirstName: '',
            clientLastName: '',
            projectName: '',
            dueDateFrom: '',
            dueDateTo: '',
            invoiceStartDate: invoiceDateFromFilter,
            invoiceEndDate: invoiceDateToFilter,
            isActive: true,
            isUpworkProject: true,
            paidDateFrom: '',
            paidDateTo: '',
            paymentStatus: '',
            invoiceNumber: "",
            invoiceType: 1,
            projectManagerFirstName: "",

        };
        getAllInvoiceListAPI(body, "PROJECTNAME", "ASC", 0, 100)
            .then((resp) => {
                // setInvoiceRows(resp?.data?.list);
                // props.setTotalCount(resp?.data?.totalCount);
                setStartLoader(false);
                setInvoiceResp(resp?.data?.list);


            })
            .catch((err) => {
                props.setStartLoader(false);
            });
    }

    const getAllInvoices = () => {
        setIsGetAllInvoice(true); // Default state to get All Invoice Data
        setIsAddInvoice(false);
        setIsGetInvoiceByid(false);
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        setIsSortInvoice(false);
        setIsDeleteInvoice(false);
        invoiceItemsDataArray.splice(0, invoiceItemsDataArray.length);
        getAllProjAPICall();
        navigate("/weekly-invoice");
    };
    const getAllInvoiceAPICall = () => {

        setStartLoader(true);
        getAllInvoiceListAPI(body, sortInvoiceData, sortOrder, page, rowsPerPage)
            .then((resp) => {
                setResponse(resp?.data?.list);
                setTotalCount(resp?.data?.totalCount);
                setStartLoader(false);
            })
            .catch((err) => {
                setStartLoader(false);
            });
    }

  
    const getInvoiceById = (row) => {
        setIsGetAllInvoice(false);
        setIsGetInvoiceByid(true); // Get Deta of Invoice through Id, user will clicks on Row of Data table and it will set true state.
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        navigate(`/weekly-invoice/?id=${row.id}`);
    };
    const editInvoice = () => {
        setIsGetAllInvoice(false);
        setIsAddInvoice(false);
        setIsGetInvoiceByid(false);
        setIsEditInvoice(true); // Edit Invoice page
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        setIsDeleteInvoice(false);
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(false);
    };
    const makeCopyInvoice = () => {
        setIsGetAllInvoice(false);
        setIsAddInvoice(false);
        setIsGetInvoiceByid(false);
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(true); // copy Invoice page
        setIsFilterInvoice(false);
        setIsDeleteInvoice(false);
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(false);
    };
    const [getProjectName, setGetProjectName] = useState();
    const deleteInvoice = (projectName) => {
        setGetProjectName(projectName);
        setIsGetAllInvoice(false);
        setIsAddInvoice(false);
        setIsGetInvoiceByid(true); // View Invoice will be at background
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        setIsDeleteInvoice(true); // popup for delete Invoice
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(false);
    };
    const handleClose = () => {
        setStatusUpdated(false);
    }
    return (
        <>
            <div className="flex-container">
                <div>
                    <Navigation myClass="Upwork Hours" />
                </div>
                <div className="right-section">
                    <div className="title" id="title">
                        {isGetAllInvoice ? "" : <BackButton handleClick={getAllInvoices} />}
                        <div className="pageheading">
                            <p>Upwork Hours</p>
                        </div>



                        <Settings />
                    </div>


                    <div className="content" id="content">
                        {isGetAllInvoice ? (
                            <GetAllWeeklyInvoice
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                totalCount={totalCount}
                                response={response}
                                setStartLoader={setStartLoader}
                                getInvoiceById={getInvoiceById}
                                invoiceType={invoiceType}
                                setTotalCount = {setTotalCount}
                                sortInvoiceData={sortInvoiceData}
                                isActiveFilter={isActiveFilter}
                                setIsActiveFilter={setIsActiveFilter}
                                handleInvoiceCheck={handleInvoiceCheck}
                                projectID={projectID}
                                setProjectID={setProjectID}
                                updateStatusBody={updateStatusBody}
                                setUpdateStatusBody={setUpdateStatusBody}
                                getPaymentStatus={getPaymentStatus}
                                getAllCLients={getAllCLients}
                                getAllProjects={getAllProjects}
                                handlePaymentStatusChange={handlePaymentStatusChange}
                                updateInvoiceStatus={updateInvoiceStatus}
                                clientFirstNameFilter={clientFirstNameFilter}
                                // setClientFirstNameFilter={setClientFirstNameFilter}
                                // setClientLastNameFilter={setClientLastNameFilter}
                                setProjectNameFilter={setProjectNameFilter}
                                setDueDateFromFilter={setDueDateFromFilter}
                                setDueDateToFilter={setDueDateToFilter}
                                setInvoiceDateFromFilter={setInvoiceDateFromFilter}
                                setInvoiceDateToFilter={setInvoiceDateToFilter}
                                setPaidDateFromFilter={setPaidDateFromFilter}
                                setPaidDateToFilter={setPaidDateToFilter}
                                setIsPaymentStatusFilter={setIsPaymentStatusFilter}
                                setIsFilterInvoice={setIsFilterInvoice}
                                getAllInvoices={getAllInvoices}
                                onFiltersApplied={handleFiltersApplied}
                                pastFourWeeks={pastFourWeeks}
                                currentWeek={currentWeek}
                                handleWeekChange={handleWeekChange}
                                invoiceDateFromFilter = {invoiceDateFromFilter}
                                invoiceDateToFilter = {invoiceDateToFilter}
                                upworkInvoiceGenerated = {upworkInvoiceGenerated}
                                setUpworkInvoiceGenerated = {setUpworkInvoiceGenerated}
                                isInvoiceGenerated = {isInvoiceGenerated}
                                showInvoices = {showInvoices}
                                setShowInvoices = {setShowInvoices}
                                invoiceResp = {invoiceResp}
                            />
                        )

                        : isGetInvoiceByid ? (
                            <ViewContent
                                setinvoiceNumber={setinvoiceNumber}
                                editInvoice={editInvoice}
                                makeCopyInvoice={makeCopyInvoice}
                                deleteInvoice={deleteInvoice}
                                setStartLoader={setStartLoader}
                                setUpdateInvoiceNumberWindow={setUpdateInvoiceNumberWindow}
                                setAlertPopupWindow={setAlertPopupWindow}
                                setAlertPopupMessage={setAlertPopupMessage}
                                fromPage = "Weekly"
                            />
                        )
                          
                        : (
                        <p>No Content</p>
                        )}
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
            {alertPopupWindow.is &&
                <InvoiceAlertPopupMessage
                    setAlertPopupWindow={setAlertPopupWindow}
                    alertPopupMessage={alertPopupMessage}
                    setAlertReponse={setAlertReponse}
                    alertPopupWindow={alertPopupWindow}
                ></InvoiceAlertPopupMessage>
            }
            {updateInvoiceNumberWindow && <></>
            }
            {
                startLoader ? <Loader /> : <></>
            }
            {
                statusUpdated &&
                <Snackbar open={statusUpdated} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Payment status updated successfully
                    </Alert>
                </Snackbar>
            }
            {error && <ErrorPopup errorMessage={error} onClose={closeErrorPopup} />}
        </>
    );
}
