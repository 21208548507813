import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getInvoiceByIdAPI,
  getMappedTransaction,
  downloadInvoiceAPI,
  getFullyPaidDetails,
} from "../../../utils/api-helper";
import "./viewContent.css";
import "./viewPdf.css";
import EditContentButton from "../../../components/Buttons/ModifyContent";
import DeleteContentButton from "../../../components/Buttons/DeleteContent";
import { invoiceItemsDataArray } from "../index";
import TransactionTable from "../../../components/Tables/paymentTable";
import { ReactComponent as SuccessCheck } from "../../../assets/icons/largeSuccess.svg";
import { ReactComponent as UnpaidIcon } from "../../../assets/icons/unpaidIcon.svg";
import Tooltip from '@mui/material/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import { ReactComponent as PartiallyPaidIcon } from "../../../assets/icons/partiallyPaidIcon.svg";

import { setWeekYearWithOptions } from "date-fns/fp";
import { currentDate } from "../../../components/DatePicker/dateFormater";
import { width } from "@mui/system";

export var responseData = [];

export default function Index(props) {
  const [response, setResponse] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [stringifyInvoice, setStringifyInvoice] = useState("");
  const [transactionRows, setTransactionRows] = useState([]);
  const [itemRows, setItemRows] = useState([]);
  const [dueDate, setDueDate] = useState();
  const [paymentStatusID, setPaymentStatusID] = useState();
  const location = useLocation();
  const isDashboard = location.pathname.startsWith("/dashboard");
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    let currentDate = `${year}-${month}-${day}`;
    return currentDate >= dueDate;
  };

  const decimal = (value) => {
    let decimalValue = parseFloat(value);
    return decimalValue.toFixed(2);
  };

  function createData(formattedDate, amount, transactionDetails, paymentDetailAmount) {
    const dateObject = new Date(parseInt(formattedDate));
    const day = dateObject.getUTCDate().toString().padStart(2, "0");
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = dateObject.getUTCFullYear().toString();
    const date = `${year}-${month}-${day}`;

    return { date, amount, transactionDetails, paymentDetailAmount };
  }

  useEffect(() => {
    setItemRows([]);
    if (id) {
      props.setStartLoader(true);
      getInvoiceInfo(id);

      getMappedTransaction(id)
        .then((res) => {
          setTransactionRows(
            res.data.map((row) =>
              createData(
                row.date,
                row.amountReceived,
                row.comment,
                row.paymentDetailAmount
              )
            )
          );
        })
        .catch((err) => {
          console.error("API Error:", err);
        });

      // getFullyPaidDetails(id)
      //   .then((res) => {
      //     setForceFullyPaidData(res.data);
      //   })
      //   .catch((err) => {
      //   }).finally(()=>{
      //     props.setStartLoader(false)
      //   })
    }
  }, [id]);

  const getInvoiceInfo = (id) => {
    getInvoiceByIdAPI(id)
      .then((res) => {
        setResponse(res?.data);
        setDueDate(res.data.dueDate);
        setItemRows(res?.data?.invoiceItems);
        setPaymentStatusID(res?.data?.paymentStatusId);
        responseData = res?.data;
        props.setinvoiceNumber(res?.data?.invoiceNumber);
        if (res?.data?.invoiceItems) {
          res?.data?.invoiceItems.map((item) => {
            invoiceItemsDataArray.push(item);
          });
        }
        setSuccess(true);
      })
      .catch((err) => {
        setSuccess(false);
      }).finally(() => {
        props.setStartLoader(false)
      })
  };

  const downloadInvoice = () => {
    downloadInvoiceAPI(response.id, response.invoiceNumber)
      .then((res) => {
        const fileName =
          response.projectName + " " + response.invoiceNumber + ".pdf";
        // var blob = new Blob([res], { type: 'application/pdf' });

        let link = document.createElement("a");
        const fileURL = window.URL.createObjectURL(res);
        link.setAttribute("href", fileURL);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
      });
  };

  const transactionColumns = [
    { id: "date", label: "Date", align: "left", width: 12 },
    { id: "amount", label: "Amount Received", width: 12 },
    { id: "transactionDetails", label: "Transaction\u00a0Details", width: 12 },
    { id: "paymentDetailAmount", label: "Bank\u00a0Transaction", width: 12 }
  ];

  const itemsColumns = [
    { id: "itemDescription", label: "Item\u00a0Description", width: 12 },
    { id: "sacCode", label: "SAC\u00a0Code", width: 12 },
    { id: "quantity", label: "Qty", width: 12 },
    { id: "hours", label: "Hours", width: 12 },
    { id: "hourlyRate", label: "Rate", width: 12 },
    { id: "itemAmount", label: "Amount", width: 12 },
  ];

  const extractNumber = (str) => {
    const matches = str.match(/[0-9,.]+/); // Regular expression to match numbers with commas and dots
    if (matches && matches.length > 0) {
      const numberString = matches[0].replace(/,/g, "");
      return parseFloat(numberString);
    }
    return "--"; // Default value if no number is found
  };

  return (
    <>
      {success ? (
        <div
          className="inner-content"
          style={{ height: "88%", overflowY: "scroll" }}
        >
          <div className="invoice_header">
            <div
              style={{ color: "black", fontWeight: "600", marginLeft: "15px" }}
            >
              Project: {response.projectName}
            </div>
            {response.purchaseOrderNumber && (
              <div
                style={{ color: "black", fontWeight: "600", marginLeft: "15px", }}
              >
                Purchase Order: {response.purchaseOrderNumber}
              </div>
            )}
            <div style={{ color: "grey", fontWeight: "600" }}>
              Client: {`${response.clientFirstName} ${response.clientLastName}`}
            </div>

            <div>
              {!(props.fromPage == 'Weekly') && <><div className="functional_elements">
                <div onClick={props.editInvoice}>
                  <EditContentButton small={false} />
                </div>
              </div><div className="functional_elements">
                  <button onClick={props.makeCopyInvoice}>Make A Copy</button>
                </div></>}

              <div className="functional_elements">
                <button onClick={downloadInvoice}>Download Invoice</button>
              </div>
            </div>
          </div>
          <div className="data-container">
            <div>
              <div id="details">
                <h3 className="section_heading">{`Invoice Details:  ${response.invoiceNumber} `}</h3>
                <div className="data_sub_container">
                  <div className="column">
                    <h4 className="sub_heading">Invoice Date</h4>
                    <p>{response.invoiceDate ? response.invoiceDate : "--"}</p>
                  </div>
                  <div className="column">
                    <h4 className="sub_heading">Due Date</h4>
                    <p style={{ color: getCurrentDate() ? "red" : "" }}>
                      {response.dueDate ? response.dueDate : "--"}
                    </p>
                  </div>
                  <div className="column">
                    <h4 className="sub_heading">Total Amount</h4>
                    <p>
                      {response.invoiceTotalAmount
                        ? response.invoiceTotalAmount
                        : "--"}
                    </p>
                  </div>
                </div>
              </div>
              <div id="details">
                <div className="data_sub_container">
                  <div className="column">
                    <h4 className="sub_heading">Invoice Summary</h4>
                    <p>
                      {response.invoiceSummary ? response.invoiceSummary : "--"}
                    </p>
                  </div>
                  <div className="column">
                    <h4 className="sub_heading">Invoice Note</h4>
                    <p>{response.note ? response.note : "--"}</p>
                  </div>
                  <div className="column">
                    <h4 className="sub_heading">Invoice Description</h4>
                    <p>
                      {response.invoiceDescription
                        ? response.invoiceDescription
                        : "--"}
                    </p>
                  </div>
                </div>
              </div>
              <div id="details">
                <div className="data_sub_container">
                  <div className="column">
                    <h4 className="sub_heading">Invoice Period</h4>
                    <p>
                      {response.invoiceStartDate
                        ? response.invoiceStartDate
                        : "--"}
                      {" To "}
                      {response.invoiceEndDate ? response.invoiceEndDate : "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div id="details">
                <h3 className="section_heading">{"Payment Details"}</h3>
                <div className="data_sub_container">
                  <div className="column">
                    <h4
                      className="sub_heading"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Payment Status
                      {response.paymentStatusId === 3 ? (
                        <Tooltip placement="right-start" componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: '15px', // Adjust font size here
                            }
                          }
                        }} title="To record payment entry, please visit the 'Pending Invoice' section." arrow>
                          <span  >
                            <SuccessCheck style={{ fontSize: '24px', cursor: 'pointer' }} />
                          </span>
                        </Tooltip>
                      ) : response.paymentStatusId === 4 ? (

                        <Tooltip placement="right-start" componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: '15px', // Adjust font size here
                            }
                          }
                        }} title="To record payment entry, please visit the 'Pending Invoice' section."  arrow>
                          <span  >
                            <PartiallyPaidIcon style={{ fontSize: '24px', cursor: 'pointer' }} />
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip placement="right-start" componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: '15px', // Adjust font size here
                            }
                          }
                        }} title="To record payment entry, please visit the 'Pending Invoice' section."  arrow>
                          <span  >
                            <UnpaidIcon style={{ fontSize: '24px', cursor: 'pointer' }} />
                          </span>
                        </Tooltip>
                      )}
                    </h4>
                    <p>
                      {response.paymentStatus ? response.paymentStatus : "--"}
                    </p>
                  </div>
                  <div className="column">
                    <h4 className="sub_heading">Payment Date</h4>
                    <p>{response.paidDate ? response.paidDate : "--"}</p>
                  </div>
                  <div className="column">
                    <h4 className="sub_heading">Paid Amount</h4>
                    <p>{response.amountPaid ? response.amountPaid : "--"}</p>
                  </div>
                </div>
              </div>
              <div id="details">
                <div className="data_sub_container">
                  <div className="column">
                    <h4 className="sub_heading">Payment Mode</h4>
                    <p>{response.paymentMode ? response.paymentMode : "--"}</p>
                  </div>
                  <div className="column">
                    <h4 className="sub_heading">Currency</h4>
                    <p>
                      {response.currencyName ? response.currencyName : "--"}
                    </p>
                  </div>
                  <div className="column">
                    <h4 className="sub_heading">Total Amount</h4>
                    <p>
                      {response.invoiceTotalAmount
                        ? response.invoiceTotalAmount
                        : "--"}
                    </p>
                  </div>
                </div>
              </div>
              <div id="details">
                <div className="data_sub_container">
                  <div className="column">
                    <h4 className="sub_heading" style={{ display: 'flex', alignItems: 'center' }}>GST
                      <span>
                        <Tooltip placement="right-start" componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: '15px', // Adjust font size here
                            }
                          }
                        }} title="18% of total amount" arrow>
                          <span  >
                            <FaInfoCircle style={{ fontSize: '20px', cursor: 'pointer' }} />
                          </span>
                        </Tooltip>
                      </span>
                    </h4>

                    <p>
                      {response.sgst && response.cgst
                        ? `₹ ${(response.sgst + response.cgst).toFixed(2)}`
                        : " -- "}
                    </p>
                  </div>
                  <div className="column">
                    <h4 className="sub_heading" style={{ display: 'flex', alignItems: 'center' }}>Grand Total
                      <span>
                        <Tooltip placement="right-start" componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: '15px', // Adjust font size here
                            }
                          }
                        }} title="GST + Total Amount" arrow>
                          <span  >
                            <FaInfoCircle style={{ fontSize: '20px', cursor: 'pointer' }} />
                          </span>
                        </Tooltip>
                      </span>
                    </h4>
                    <p>{response.grandTotal ? response.grandTotal : "--"}</p>
                  </div>
                  <div className="column">
                    <h4 className="sub_heading">Due Amount
                      <span>
                        <Tooltip placement="right-start" componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: '15px', // Adjust font size here
                            }
                          }
                        }} title="Amount Receivable - Paid Amount" arrow>
                          <span  >
                            <FaInfoCircle style={{ fontSize: '20px', cursor: 'pointer' }} />
                          </span>
                        </Tooltip>
                      </span>
                    </h4>
                    <p style={{ color: "red" }}>
                      {response.amountDue && response.paymentStatusId !== 3
                        ? response.amountDue
                        : "--"}
                    </p>
                  </div>
                </div>
              </div>
              <div id="details">
                <div className="data_sub_container">
                  <div className="column">
                    <h4 className="sub_heading">Payment Terms</h4>
                    <p>{response.paymentTerm ? response.paymentTerm : "--"}</p>
                  </div>
                  <div className="column">
                    <h4 className="sub_heading" style={{ display: 'flex', alignItems: 'center' }}>Amount Receivable
                      <span>
                        <Tooltip placement="right-start" componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: '15px', // Adjust font size here
                            }
                          }
                        }} title="90% of Total Amount + GST Amount" arrow>
                          <span  >
                            <FaInfoCircle style={{ fontSize: '20px', cursor: 'pointer' }} />
                          </span>
                        </Tooltip>
                      </span>
                    </h4>
                    <p>
                      {" "}
                      {response.amountReceivable
                        ? `${response.amountReceivable}`
                        : "--"}
                    </p>
                  </div>
                  <div className="column">
                    {/* <h4 className="sub_heading">Amount Receivable</h4>
                    <p>
                      {" "}
                      {response.amountReceivable
                        ? `${response.amountReceivable}`
                        : "--"}
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            {/* item table */}
            <div>
              <div id="details">
                <h3 className="section_heading">{"Item Details"}</h3>
                <div className="data_sub_container">
                  <TransactionTable
                    style={{ backgroundColor: "#c5c5c5" }}
                    paginationStyle={{
                      position: "fixed",
                      right: "20px",
                      bottom: "20px",
                      backgroundColor: "#ffffff",
                    }}
                    setSortOrder={props.setSortOrder}
                    sortOrder={props.sortOrder}
                    setsortInvoiceData={props.setsortInvoiceData}
                    columns={itemsColumns}
                    normalData={true}
                    title={"itemDetails"}
                    rows={itemRows}
                    sortBy={props.sortBy}
                    setSortBy={props.setSortBy}
                    sortInvoiceData={props.sortInvoiceData}
                    setHoveredRowIndex={props.setHoveredRowIndex}
                    hoveredRowIndex={props.hoveredRowIndex}
                    selectedStatus={props.selectedStatus}
                    handlePaymentDetailCheck={props.handlePaymentDetailCheck}
                    setSelectedStatus={props.setSelectedStatus}
                    paymentDetailID={props.paymentDetailID}
                  />
                </div>
              </div>
            </div>
            <div>
              <div id="details">
                <h3 className="section_heading" style={{ marginTop: "15px" }}>
                  {"Mapped Payment Transaction(s)"}
                </h3>
                <div className="data_sub_container" style={{ margin: "0px" }}>
                  <TransactionTable
                    style={{ backgroundColor: "#c5c5c5", height: "20vh" }}
                    paginationStyle={{
                      position: "fixed",
                      right: "20px",
                      bottom: "20px",
                      backgroundColor: "#ffffff",
                    }}
                    setSortOrder={props.setSortOrder}
                    sortOrder={props.sortOrder}
                    setsortInvoiceData={props.setsortInvoiceData}
                    columns={transactionColumns}
                    normalData={true}
                    title={"invoiceDetails"}
                    paymentStatusID={paymentStatusID}
                    rows={transactionRows}
                    setRows={setTransactionRows}
                   // fullyPaidRows={forceFullyPaidData}
                    sortBy={props.sortBy}
                    setSortBy={props.setSortBy}
                    sortInvoiceData={props.sortInvoiceData}
                    setHoveredRowIndex={props.setHoveredRowIndex}
                    hoveredRowIndex={props.hoveredRowIndex}
                    selectedStatus={props.selectedStatus}
                    handlePaymentDetailCheck={props.handlePaymentDetailCheck}
                    setSelectedStatus={props.setSelectedStatus}
                    paymentDetailID={props.paymentDetailID}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
