import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navigation";
import Footer from "../../components/footer/Footer";
import Settings from "../../components/Settings";
import downloadImage from "../../assets/images/downloadImage.png";
import { ReactComponent as UploadIcon } from "../../assets/icons/UploadIcon.svg";
import GetAllPendingInvoices from "./getAllPendingInvoices";
import {
  getAllPendingInvoices,
  deleteUnMappedTransactions,
  getTransactionFile,
  getUploadedFileStatus,
  updateInvoiceDetail,
  getUploadError,
  saveImportedFile,
  getPaymentStatusAPI,
  updateMultipleInvoiceStatus,
} from "../../utils/api-helper";
import Loader from "../../components/Loader";
import TransactionDetails from "./transactionDetails";
import Filelist from "./fileLists";
import Popup from "./confirmationPopup.jsx";
import UpdateStatusPopup from "./UpdateStatusPopup.jsx";
import ErrorPopup from "../../components/ErrorPopup";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./pendingInvoice.module.css";
import UnmappedTransactionModal from "./unmappedTransactionModal.jsx";

export default function Index() {
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [response, setResponse] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [updateStatusPopup, setUpdateStatusPopup] = useState(false);
  //table-states
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  //upload states
  const [isImageLoading, setIsFileLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  const [invoiceID, setInvoiceID] = useState([]);
  const [paymentDetailID, setPaymentDetailID] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [fileID, setFileID] = useState(null);
  const [fileCurrentStatus, setFileCurrentStatus] = useState();
  const [fetchedStatus, setFetchedStatus] = useState(false);
  const [fileStatusMessage, setFileStatusMessage] = useState();
  const [showTransactionTable, setShowTransactionTable] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [mappedInvoice, setMappedInvoice] = useState("");

  //error-popUp states
  const [isError, setIsError] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [error, setError] = useState(null);
  const [toUpdateStatus, setToUpdateStatus] = useState(false);
  const [reason, setReason] = useState("");

  //for updating Status
  const [statusInvoiceID, setStatusInvoiceId] = useState(null);
  const [amountDue, setAmountDue] = useState(null);
  const [invoicePaymentStatus, setInvoicePaymentStatus] = useState(null);
  const [selectedInvoiceList, setSelectedInvoiceList] = useState([]);
  const [isManualTransaction, setIsManualTransaction] = useState(false);
  const [errorPopupMessage, setErrorPopupMessage] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  //unamappedTransaction
  const [showUmappedTransactionModal, setShowUmappedTransactionModal] =
    useState(false);

  const closeErrorPopup = () => {
    setError(null);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function handleManualTransaction() {
    if (checkAmountDue()) {
      setIsManualTransaction(true);
      showReasonPopup();
    }
  }

  function handleBankTransaction() {
    if (checkAmountDue()) {
      setIsManualTransaction(false);
      showReasonPopup();
    }
  }
  function checkAmountDue() {
    const hasNullAmountDue = selectedInvoiceList.some(invoice => invoice.AmountDue === null);
    if (hasNullAmountDue) {
      setErrorPopupMessage("Amount due is null for one or more selected invoices.");
      setShowErrorPopup(true);
      return false;
    }
    return true;
  }

  function updateInvoiceStatusManual(updatedInvoices) {
    setStartLoader(true);
    setShowPopUp(false);
  
    const requestBody = updatedInvoices.map(invoice => ({
      invoiceId: invoice.Id,
      paymentStatus: getPaymentStatusValue(invoice.paymentStatusId),
      comment: invoice.comment,
      amountReceived: invoice.AmountReceived
    }));
  
    updateMultipleInvoiceStatus(requestBody)
      .then((res) => {
        setMappedInvoice(res.data);
        getPendingInvoices();
        resetAllStates();
      })
      .catch((error) => {
        console.error("Error in updateInvoiceStatusManual:", error);
        handleError(error);
      })
      .finally(() => {
        setStartLoader(false);
      });
  }
  
  function updateInvoiceStatusBank(updatedInvoices) {
    setStartLoader(true);
    const invoices = updatedInvoices.map(invoice => ({
      invoiceId: invoice.Id,
      paymentStatus: invoice.paymentStatusId,
      comment: invoice.comment,
      amountReceived: invoice.AmountReceived
    }));
  
    return new Promise((resolve, reject) => {
      updateInvoiceDetail(paymentDetailID, invoices)
        .then((res) => {
          setMappedInvoice(res.data);
          setShowPopUp(false);
          getPendingInvoices();
          resetAllStates();
          resolve(res.data);
        })
        .catch((error) => {
          handleError(error);
          reject(error);
        })
        .finally(() => {
          setStartLoader(false);
        });
    });
  }
  
  function getPaymentStatusValue(statusId) {
    const status = paymentStatus.find(status => status.id === statusId);
    return status ? status.value : '';
  }
  
  function resetAllStates() {
    // Reset states from previous implementation
    setToUpdateStatus(false);
    setStatusInvoiceId(null);
    setAmountDue(null);
    setInvoicePaymentStatus(null);
    setInvoiceID([]);
    setPaymentDetailID(null);
    setSelectedInvoiceList([]);
    setTransaction(null);
    setIsManualTransaction(false);
    setUpdateStatusPopup(false);
    setSearchText("");
    setPage(0);
    setRowsPerPage(10);
    setHoveredRowIndex(null);
    setSelectedStatus(null);
    setIsFileLoading(false);
    setFileError("");
    setFileName("");
    setIsFileUploaded(false);
    setFileID(null);
    setFileCurrentStatus(undefined);
    setFetchedStatus(false);
    setFileStatusMessage(undefined);
    setShowTransactionTable(false);
    setShowFileList(false);
    setShowPopUp(false);
    setError(null);
    setReason("");
    setShowErrorPopup(false);
    setShowUmappedTransactionModal(false);
  }
  
  
  function handleError(error) {
    setStartLoader(false);
    if (error.response) {
      setError(error.response.data.message);
    } else if (error.request) {
      setError("Something went wrong, try again");
    } else {
      setError("Something went wrong, try again");
    }
  }

  const showReasonPopup = (reason) => {
    setIsError(false);
    setToUpdateStatus(true);
    setReason(reason);
    setIsError(false);
    setUpdateStatusPopup(true);
  };

  const showConfirmationPopup = (invoice) => {
    setMappedInvoice(invoice);
    setIsError(false);
    setShowPopUp(true);
    setToUpdateStatus(false);
  };

  const [sortInvoiceData, setsortInvoiceData] = useState("invoiceDate");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortBy, setSortBy] = useState("DATE");
  const [showFileList, setShowFileList] = useState(false);

  function handleHistoryTableClick(id, name) {
    setFileID(id);
    setFileName(name);
    setShowTransactionTable(true);
    setShowFileList(false);
  }

  useEffect(() => {
    getPendingInvoices();
    getPaymentStatusAPI()
      .then((res) => {
        setPaymentStatus(res.data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.message);
        } else if (error.request) {
          setError("Something went wrong, try again");
        } else {
          setError("Something went wrong, try again");
        }
      });
  }, [sortInvoiceData, sortOrder, page, rowsPerPage]);

  const handlePaymentDetailCheck = (event, rowId, row) => {
    const checked = event.target.checked;
    const obj = {
      transactionDetails: row.transactionDetails,
      transactionDate: row.date,
      transactionAmount: row.amount,
    };
    if (checked) {
      setPaymentDetailID(rowId);
      setSelectedInvoiceList([]);
      setTransaction(obj);
    } else {
      setPaymentDetailID(null);
      setTransaction(null);
    }
  };

  function deleteUnmappedTransaction() {
    setStartLoader(true);
    deleteUnMappedTransactions(1)
      .then((res) => { })
      .catch((err) => {
      })
      .finally(() => {
        setStartLoader(false);
        setShowUmappedTransactionModal(false);
      });
  }
  function handlePaymentStatusChange(value, id) {
    if (!paymentDetailID) {
      setInvoicePaymentStatus(value);
    } else {
      setInvoiceID((prevInvoiceID) => {
        return prevInvoiceID.map((item) => {
          if (item.id === id) {
            return { ...item, paymentStatus: value };
          }
          return item;
        });
      });
    }
    setSelectedInvoiceList((prevSelectedInvoiceList) =>
      prevSelectedInvoiceList.map((item) =>
        item.Id === id ? { ...item } : item
      )
    );
  }
  useEffect(() => {
  }, [invoicePaymentStatus]);
  function handleInvoiceCheck(isChecked, row) {
    const id = row.id;
    const paymentStatus = row.paymentStatus;
    const amountDue = row.amountDue;
    const body = {
      Id: id,
      ClientName: row.clientName,
      ProjectName: row.projectName,
      Invoice: row.invoiceNumber,
      InvoiceAmount: row.grandTotal,
      AmountDue: row.amountDue,
    };

    setInvoiceID(prevInvoiceID => {
      const updatedInvoiceID = isChecked
        ? [...prevInvoiceID, { id, checked: true, paymentStatus }]
        : prevInvoiceID.filter(item => item.id !== id);
      return updatedInvoiceID;
    });

    setSelectedInvoiceList(prevList => {
      const updatedList = isChecked
        ? [...prevList, body]
        : prevList.filter(invoice => invoice.Id !== id);
      return updatedList;
    });

  }
  const childRef = useRef();

  function updateInvoice() {
    setStartLoader(true);
    const updatedData = invoiceID.map((obj) => {
      const { checked, ...rest } = obj;
      const selectedOption = paymentStatus.find(
        (option) => option.value === obj.paymentStatus
      );
      const id = selectedOption ? selectedOption.id : null;
      return {
        ...rest,
        ["invoiceId"]: obj.id,
        paymentStatus: id,
      };
    });
    const body = updatedData.map((obj) => {
      const { id, ...rest } = obj;
      return rest;
    });

    updateInvoiceDetail(paymentDetailID, body)
      .then((res) => {
        if (childRef.current) {
          childRef.current.getTransactionDetails();
        }
        setMappedInvoice(res.data);
        showConfirmationPopup(res.data);
        getPendingInvoices();
        setInvoiceID([]);
        setPaymentDetailID(null);
        setStartLoader(false);
        setStatusInvoiceId(null);
        setAmountDue(null);
        setInvoicePaymentStatus(null);
      })
      .catch((error) => {
        setStartLoader(false);
        if (error.response) {
          setError(error.response.data.message);
        } else if (error.request) {
          setError("Something went wrong, try again");
        } else {
          setError("Something went wrong, try again");
        }
      })
      .finally(() => {
        getPendingInvoices();
        setStartLoader(false);
      });
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value);
    setPage(0);
  };

  function getPendingInvoices() {
    setStartLoader(true);
    const body = {
      clientFirstName: "",
      clientLastName: "",
      projectName: "",
      dueDateFrom: "",
      dueDateTo: "",
      invoiceDateFrom: "",
      invoiceDateTo: "",
      isActive: false,
      paidDateFrom: "",
      paidDateTo: "",
      paymentStatus: "",
      invoiceNumber: "",
      invoiceType: "1",
    };
    getAllPendingInvoices(body, sortInvoiceData, sortOrder, page, rowsPerPage)
      .then((res) => {
        setResponse(res?.data?.list);
        setTotalCount(res?.data?.totalCount);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.message);
        } else if (error.request) {
          setError("Something went wrong, try again");
        } else {
          setError("Something went wrong, try again");
        }
      })
      .finally(() => {
        setStartLoader(false);
      });
  }

  //File Status
  function getFileStatus() {
    setStartLoader(true);
    getUploadedFileStatus(fileID)
      .then((res) => {
        setFetchedStatus(true);
        setFileCurrentStatus(res.data);
        setFileStatusMessage(res.message);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.message);
        } else if (error.request) {
          setError("Something went wrong, try again");
        } else {
          setError("Something went wrong, try again");
        }
      })
      .finally(() => {
        setStartLoader(false);
      });
  }

  function getFileError() {
    setStartLoader(true);
    getUploadError(fileID)
      .then((res) => {
        showErrorPopup(res.data);
        setShowPopUp(true);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.message);
        } else if (error.request) {
          setError("Something went wrong, try again");
        } else {
          setError("Something went wrong, try again");
        }
      })
      .finally(() => {
        setStartLoader(false);
      });
  }

  // Upload file
  function validateFile(file) {
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024); // Convert file size to MB

      if (fileSizeInMB > 5) {
        setFileError("File size exceeds 5MB");
        setIsFileLoading(false);
        return false;
      }

      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (fileExtension && !["xlsx"].includes(fileExtension)) {
        setFileError("Invalid file type. Only XLSX files are allowed");
        setIsFileLoading(false);
        return false;
      }
    }
    return true;
  }

  const handleUploadIconClick = () => {
    setFileError("");
    const uploadInput = document.getElementById("uploadInput");
    if (uploadInput) {
      uploadInput.click();
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileUpload = (event) => {
    setIsFileLoading(true);
    setFileError("");
    const file = event.target.files?.[0];

    if (file) {
      setStartLoader(true);
      if (validateFile(file)) {
        const formData = new FormData();
        formData.append("file", file);

        getTransactionFile(formData)
          .then((res) => {
            setFileID(res.data.id);
            setFileName(res.data.fileName);
            setIsFileUploaded(true);
            saveImportedFile(res.data.id)
              .then((res) => { })
              .catch((error) => {
                if (error.response) {
                  setError(error.response.data.message);
                } else if (error.request) {
                  setError("Something went wrong, try again");
                } else {
                  setError("Something went wrong, try again");
                }
              });
            // setFileID
          })
          .catch((error) => {
            if (error.response) {
              setError(error.response.data.message);
            } else if (error.request) {
              setError("Something went wrong, try again");
            } else {
              setError("Something went wrong, try again");
            }
          })
          .finally(() => {
            setStartLoader(false);
          });
      } else {
        setStartLoader(false);
      }
    } else {
      setIsFileLoading(false);
      setStartLoader(true);
    }
  };

  const handleDrop = (event) => {
    setIsFileLoading(true);
    setFileError("");
    event.preventDefault();

    const file = event.dataTransfer.files?.[0];

    if (file) {
      setStartLoader(true);
      if (validateFile(file)) {
        const formData = new FormData();
        formData.append("file", file);

        getTransactionFile(formData)
          .then((res) => {
            setFileID(res.data.id);
            setIsFileUploaded(true);

            saveImportedFile(res.data.id)
              .then((res) => { })
              .catch((error) => {
                if (error.response) {
                  setError(error.response.data.message);
                } else if (error.request) {
                  setError("Something went wrong, try again");
                } else {
                  setError("Something went wrong, try again");
                }
              });
          })
          .catch((error) => {
            if (error.response) {
              setError(error.response.data.message);
            } else if (error.request) {
              setError("Something went wrong, try again");
            } else {
              setError("Something went wrong, try again");
            }
          })
          .finally(() => {
            setIsFileLoading(false);
            setStartLoader(false);
          });
      } else {
        setStartLoader(false);
      }
    } else {
      setIsFileLoading(false);
      setStartLoader(false);
    }
  };

  const handleReset = () => {
    setIsFileUploaded(false);
    setFetchedStatus(false);
    setShowTransactionTable(false);
    setFileCurrentStatus();
    setInvoiceID([]);
    setSearchText("");
    setShowFileList(false);
  };

  return (
    <>
      <div className="flex-container">
        <div>
          <Navigation myClass="Pending Invoices" />
        </div>
        <div className="right-section">
          <div className="title" id="title">
            <div className="pageheading" style={{ marginLeft: "28px" }}>
              <p>Pending Invoices</p>
            </div>
            <Settings />
          </div>
          <div
            className="content"
            id="content"
            style={{
              padding: "25px",
              display: "flex",
              flexDirection: "column",
              overflowX: "hidden",
              overflowY: "auto",
              height: "81%",
            }}
          >
            {/* Upper-Section */}
            <div className={styles.tableHeading}>
              <h4>{"Recieved Payments"}</h4>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <button
                  className={`${styles.history} ${styles.button}`}
                  style={{}}
                  onClick={() => setShowUmappedTransactionModal(true)}
                >
                  Clear Unmapped Transactions
                </button>
                <button
                  className={`${styles.history} ${styles.button}`}
                  onClick={() => setShowFileList(true)}
                >
                  File History
                </button>
                {(showTransactionTable ||
                  fetchedStatus ||
                  isFileUploaded ||
                  showFileList) && <UploadIcon onClick={() => handleReset()} />}
              </div>
            </div>
            { }

            {showFileList ? (
              <Filelist
                handleHistoryTableClick={handleHistoryTableClick}
                setStartLoader={setStartLoader}
              />
            ) : showTransactionTable ? (
              <TransactionDetails
                ref={childRef}
                fileName={fileName}
                // sortBy={sortBy}
                // setSortBy={setSortBy}
                // sortOrder={sortOrder}
                // setSortOrder={setSortOrder}
                fileID={fileID}
                handlePaymentDetailCheck={handlePaymentDetailCheck}
                paymentDetailID={paymentDetailID}
                handleHistoryTableClick={handleHistoryTableClick}
                startLoader={startLoader}
                setStartLoader={setStartLoader}
              />
            ) : fetchedStatus ? (
              <div className="uploadSection">
                <p style={{ fontSize: "14px", paddingTop: "40px" }}>
                  {fileCurrentStatus === "SUCCESS" ||
                    fileCurrentStatus === "PARTIAL_DATA_LOADED" ||
                    fileCurrentStatus === "FAILED" ||
                    fileCurrentStatus === "IN_PROGRESS"
                    ? fileStatusMessage
                    : "Fetching data from the uploaded file, click button to view the status..."}
                </p>
                {/* possible error : white space is extra in flex class */}
                <div className={styles.flex}>
                  Current Status :{" "}
                  <p
                    style={{
                      color:
                        fileCurrentStatus === "SUCCESS"
                          ? "green"
                          : fileCurrentStatus === "FAILED"
                            ? "red"
                            : "orange",
                      marginLeft: "10px",
                    }}
                  >
                    {fileCurrentStatus}
                  </p>
                </div>
                {fileCurrentStatus === "SUCCESS" ? (
                  <button
                    className={`${styles.viewDataBtn} ${styles.button}`}
                    onClick={() => setShowTransactionTable(true)}
                  >
                    <p className={styles.btnText}>View Data</p>
                  </button>
                ) : fileCurrentStatus === "PARTIAL_DATA_LOADED" ? (
                  <div
                    style={{
                      display: "flex",
                      width: "30%",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      className={`${styles.viewErrorsBtn} ${styles.button}`}
                      onClick={getFileError}
                    >
                      <p className={styles.btnText}>View Errors</p>
                    </button>
                    <button
                      className={`${styles.viewDataBtn} ${styles.button}`}
                      onClick={() => setShowTransactionTable(true)}
                    >
                      <p className={styles.btnText}>View Data</p>
                    </button>
                  </div>
                ) : fileCurrentStatus === "FAILED" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={`${styles.viewErrorsBtn} ${styles.button}`}
                      onClick={getFileError}
                    >
                      <p className={styles.btnText}>View Errors</p>
                    </button>
                    <button
                      className={`${styles.tryAgainBtn} ${styles.button}`}
                      onClick={() => {
                        setIsFileUploaded(false);
                        setFetchedStatus(false);
                      }}
                    >
                      <p className={styles.btnText}>Try Again</p>
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className={`${styles.chckStts} ${styles.button}`}
                      onClick={getFileStatus}
                    >
                      <p className={styles.btnText}>Check Status</p>
                    </button>
                  </div>
                )}
              </div>
            ) : !isFileUploaded ? (
              <div
                className="uploadSection"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                encType="multipart/form-data"
              >
                <img
                  src={downloadImage}
                  alt="download icon"
                  style={{ cursor: "pointer" }}
                  onClick={handleUploadIconClick}
                />
                <input
                  type="file"
                  id="uploadInput"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                {fileError && <span style={{ color: "red" }}>{fileError}</span>}
              </div>
            ) : (
              <div className="uploadSection">
                <p style={{ fontSize: "14px" }}>
                  {
                    "Fetching data from the uploaded file, click button to view the status..."
                  }
                </p>
                {/* // (fileCurrentStatus !== 'SUCCESS' || fileCurrentStatus !== 'FAILED') && */}
                <div style={{ width: "30%" }}>
                  <LinearProgress />
                </div>
                <button
                  className={`${styles.chckStts} ${styles.button}`}
                  onClick={getFileStatus}
                >
                  <p className={styles.btnText}>Check Status</p>
                </button>
              </div>
            )}

            {/* Bottom- Section */}
            <div className={styles.tableHeading} style={{ marginTop: "10px" }}>
              <h4> {"Unpaid/Partially Paid Invoices"}</h4>
              <div style={{ display: "flex", gap: "5px" }}>
                <span>
                  <input
                    type={"text"}
                    className="searchBar"
                    value={searchText}
                    onChange={(input) => {
                      setSearchText(input.target.value);
                    }}
                    placeholder="Search"
                  ></input>
                </span>
                <button
                  className={`uploadbutton ${paymentDetailID === null &&
                    invoiceID.length > 0
                    ? ""
                    : "disabled"
                    }`}
                  onClick={handleManualTransaction}
                >
                  Manual Transactions
                </button>
                <button
                  className={`uploadbutton ${paymentDetailID && invoiceID.length !== 0 ? "" : "disabled"
                    }`}
                  onClick={handleBankTransaction}
                >
                  Bank Transactions
                </button>
              </div>
            </div>
            <div style={{ width: "80%" }}>
              <GetAllPendingInvoices
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                totalCount={totalCount}
                response={response}
                paymentStatus={paymentStatus}
                setSortBy={setSortBy}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setsortInvoiceData={setsortInvoiceData}
                setSortOrder={setSortOrder}
                sortInvoiceData={sortInvoiceData}
                hoveredRowIndex={hoveredRowIndex}
                setHoveredRowIndex={setHoveredRowIndex}
                setSelectedStatus={setSelectedStatus}
                selectedStatus={selectedStatus}
                handleInvoiceCheck={handleInvoiceCheck}
                invoiceID={invoiceID}
                handlePaymentStatusChange={handlePaymentStatusChange}
                search={searchText}
                statusInvoiceID={statusInvoiceID}
                paymentDetailID={paymentDetailID}
                startLoader={startLoader}
                setStartLoader={setStartLoader}
              />
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
        {/* {showPopUp && (
          <Popup
            isError={isError}
            toUpdateStatus={toUpdateStatus}
            data={errorData}
            invoiceNumber={mappedInvoice}
            setShowPopUp={setShowPopUp}
            reason={reason}
            setReason={setReason}
            amountDue={amountDue}
            updateInvoiceStatusOnly={updateInvoiceStatusOnly}
          />
        )} */}
        {updateStatusPopup && (
          <UpdateStatusPopup
            showPopUp={updateStatusPopup}
            setShowPopUp={setUpdateStatusPopup}
            selectedInvoiceList={selectedInvoiceList}
            transaction={transaction}
            setTransaction={setTransaction}
            handlePaymentStatusChange={handlePaymentStatusChange}
            updateInvoiceStatus={isManualTransaction ? updateInvoiceStatusManual : updateInvoiceStatusBank}
            paymentStatus={paymentStatus}
            isManualTransaction={isManualTransaction}
            getAllPendingInvoices={getPendingInvoices}
          />
        )}
        {error && <ErrorPopup errorMessage={error} onClose={closeErrorPopup} />}
        {showUmappedTransactionModal && (
          <UnmappedTransactionModal
            setShowUmappedTransactionModal={setShowUmappedTransactionModal}
            deleteUnmappedTransaction={deleteUnmappedTransaction}
          />
        )}
         {showErrorPopup && (
      <ErrorPopup
        errorMessage={errorPopupMessage}
        onClose={() => setShowErrorPopup(false)}
      />
    )}          
    {startLoader ? <Loader /> : <></>}
      </div>
    </>
  );
}