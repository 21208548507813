import React, { useState, useEffect } from 'react';
import Logo from '../Logo_Biz4/Logo';
import NavElement from './NavElements';
import { NavLink } from "react-router-dom";
import { getItemLocalStorage } from '../../utils/local-storage-helper';
export default function Navigation(props) {
    const [isNavBarHidden, setIsNavBarHidden] = useState(true);

    const openNavBar = () => {
        document.getElementById("slideNavBar-width").style.width = "22%";
        document.getElementById("flexNavigation").style.width = "100%";
        setIsNavBarHidden(false);
    }
    const closeNavBar = () => {
        document.getElementById("slideNavBar-width").style.width = "0%";
        document.getElementById("flexNavigation").style.width = "0%";
        setIsNavBarHidden(true);
    }
    const userRole = getItemLocalStorage('userRole');
    return (
        <>
            <div className="left-section">
                <Logo />
                <div className="menu">
                    {   (userRole == 'USER') ? <></> :
                        <NavElement title="Dashboard" menu="dashboard" data={props.myClass} />
                    }
                    <NavElement title="Manage Invoice" menu="invoice" data={props.myClass} />
                    <NavElement title="Upwork Hours" menu="weekly-invoice" data={props.myClass} />
                    <NavElement title="Manage Client" menu="client" data={props.myClass} />
                    <NavElement title="Manage Project" menu="project" data={props.myClass} />
                    <NavElement title="Manage Country" menu="country" data={props.myClass} />
                    {
                      userRole === "MANAGER" || userRole === "DELIVERY_MANAGER" || userRole === "ADMIN"  ?  <NavElement title="Revenue Projections" menu="revenue" data={props.myClass} /> :<></>
                    }
                    {
                        userRole === "ADMIN" || userRole === "ACCOUNT"  ? <NavElement title="Pending Invoices" menu="pendinginvoices" data={props.myClass} /> : <></>
                    }
                    {
                        userRole === "ADMIN" ? <NavElement title="Manage Users" menu="users" data={props.myClass} /> : <></>
                    }
                    <NavElement title="Profile" menu="profile" data={props.myClass} />
                </div>
                <div className="logoutUser">
                    <NavElement title="Logout" menu="logout" data={props.myClass} />
                </div>
            </div>
            {
                isNavBarHidden ?
                    <div className="slideNavBar" onClick={openNavBar}><p>&#9776;</p></div>
                    :
                    <div className="slideNavBar" onClick={closeNavBar}><p>&times;</p></div>
            }
            <div id="flexNavigation">
                <div className="left-section-responsive" id="slideNavBar-width">
                    <Logo />
                    <div className="menu">
                        {   (userRole == 'USER') ? <></> :
                            <NavElement title="Dashboard" menu="dashboard" data={props.myClass} />
                        }
                        <NavElement title="Manage Invoice" menu="invoice" data={props.myClass} />
                        <NavElement title="Upwork Hours" menu="weekly-invoice" data={props.myClass} />
                        <NavElement title="Manage Client" menu="client" data={props.myClass} />
                        <NavElement title="Manage Project" menu="project" data={props.myClass} />
                        <NavElement title="Manage Country" menu="country" data={props.myClass} />
                        {
                            userRole === "MANAGER" || userRole === "DELIVERY_MANAGER" || userRole === "ADMIN" ? <NavElement title="Revenue Projections" menu="revenue" data={props.myClass} /> : <></>
                        }
                        {
                            userRole === "ADMIN" || userRole === "ACCOUNT" ? <NavElement title="Pending Invoices" menu="pendinginvoices" data={props.myClass} /> : <></>
                        }
                        {
                            userRole === "ADMIN" ? <NavElement title="Manage Users" menu="users" data={props.myClass} /> : <></>
                        }
                        {/* <NavElement title="Manage Users" menu="users" data={props.myClass} /> */}
                        <NavElement title="Profile" menu="profile" data={props.myClass} />
                    </div>
                    <div className="logoutUser">
                        <NavElement title="Logout" menu="logout" data={props.myClass} />
                    </div>
                </div>
            </div>
        </>
    )
}
