import React, { useState, useEffect } from 'react'
import AddContentButton from '../../components/Buttons/AddContent';
import FilterContentButton from '../../components/Buttons/FilterContent';
import Table from '../../components/Table'
import FilterByInvoiceType from './FilterByInvoiceType';
import Badge from '@mui/joy/Badge';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { MdCancel } from "react-icons/md";
import { LuChevronUp } from "react-icons/lu";
import { LuChevronDown } from "react-icons/lu";
import { LuChevronsUpDown } from "react-icons/lu";
import { updateInvoiceAPI, updateMultipleInvoiceStatus } from '../../utils/api-helper';
import PopupMessage from './InvoiceAlertPopupMessage/index.jsx';
import UpdateStatusPopup from '../managePendingInvoices/UpdateStatusPopup.jsx';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

export default function GetAllInvoice(props) {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [rows, setRows] = useState([]);
    const [tableTitle, setTableTitle] = useState("listOfInvoices");
    const [activeFilters, setActiveFilters] = useState({});
    const [isTableEditable, setIsTableEditable] = useState(false);
    const [updateStatusBodyArray, setUpdateStatusBodyArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [alertPopupWindow, setAlertPopupWindow] = useState({ is: false, type: null });
    const [alertPopupMessage, setAlertPopupMessage] = useState('');
    const [alertResponse, setAlertResponse] = useState(false);
    const [showUpdateStatusPopup, setShowUpdateStatusPopup] = useState(false);
    const [selectedInvoicesForUpdate, setSelectedInvoicesForUpdate] = useState([]);
    const [locallyUpdatedInvoices, setLocallyUpdatedInvoices] = useState([]);
    const [pendingStatusUpdates, setPendingStatusUpdates] = useState([]);

    const handleFiltersApplied = (filters) => {
        setActiveFilters(filters);
    };
    const formatHeaderName = (fieldName) => {
        // Split the field name by capital letters
        const result = fieldName.replace(/([A-Z])/g, ' $1');
        // Capitalize the first letter of the result
        const formattedName = result.charAt(0).toUpperCase() + result.slice(1);
        return formattedName;
    };
    const handleSortClick = (order, columnId) => {

        if (order === props.sortOrder) {
            props.setsortInvoiceData(columnId);
            props.setSortOrder(order == 'ASC' ? 'DESC' : order == 'DESC' ? null : 'ASC');
        } else {
            props.setsortInvoiceData(columnId);
            props.setSortOrder(order);
        }
    };

    const getSortIcon = (column) => {
        if (column === props.sortInvoiceData && props.sortOrder === 'ASC') {
            return <LuChevronUp style={{ fontSize: "16px" }} />
        } else if (column === props.sortInvoiceData && props.sortOrder === 'DESC') {
            return <LuChevronDown style={{ fontSize: "16px" }} />
        } else {
            return <LuChevronsUpDown style={{ fontSize: "16px" }} />;
        }
    };
    const columns = [
        {
            field: "clientName", sortable: false,
            headerComponentFramework: ({ column }) => {
                return (
                    <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>
                        {getSortIcon(column.colId)}
                    </div>
                )
            }
        },
        {
            field: "projectName", sortable: false,
            headerComponentFramework: ({ column }) => {
                return (
                    <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>
                        {getSortIcon(column.colId)}
                    </div>
                )
            }
        },
        {
            field: "invoiceNumber", sortable: false,
            headerComponentFramework: ({ column }) => {
                return (
                    <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>
                        {getSortIcon(column.colId)}
                    </div>
                )
            }
        },
        {
            field: "invoiceDate", sortable: false,
            headerComponentFramework: ({ column }) => {
                return (
                    <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>
                        {getSortIcon(column.colId)}
                    </div>
                )
            }
        },
        {
            field: "dueDate", sortable: false,
            headerComponentFramework: ({ column }) => {
                return (
                    <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>
                        {getSortIcon(column.colId)}
                    </div>
                )
            }
        },
        {
            field: "paymentStatus", sortable: false,
            headerComponentFramework: ({ column }) => {
                return (
                    <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>
                        {getSortIcon(column.colId)}
                    </div>
                )
            },
            cellRenderer: ({ value, data }) => {
                const isUpdated = locallyUpdatedInvoices.some(invoice => invoice.Id === data.id);
                const style = isUpdated ? { backgroundColor: '#e6ffe6' } : {};
                if (isTableEditable) {
                    const selectedValue = updateStatusBodyArray.length > 0 ? updateStatusBodyArray.find((item) => {
                        if (data.id === item.id) {
                            return true;
                        }
                    })?.paymentStatus : value;
                    return (
                        <div>
                            <Select
                                defaultValue={value}
                                style={{ fontSize: "12px", height: "40px" }}
                                value={selectedValue}
                            >
                                {props.getPaymentStatus?.map((status) => (
                                    <MenuItem
                                        key={status.value}
                                        value={status.label}
                                        onClick={() => {
                                            addUpdatedStatus(data.id, status.label, data);
                                        }}
                                    >
                                        {status.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )
                }
                else {
                    return value;
                }
            },
        },
        {
            field: "grandTotal",
            headerName: "Invoice Amount",
            sortable: false,
            cellRenderer: ({ value, data }) => {
                const isUpdated = locallyUpdatedInvoices.some(invoice => invoice.Id === data.id) ||
                    updateStatusBodyArray.some(item => item.id === data.id);
                return (
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // padding: "8px",
                        borderRadius: "4px",
                        background: isUpdated ? '#e6ffe6' : 'transparent',
                        transition: 'all 0.3s ease'
                    }}>
                        <span style={{
                            fontWeight: 'bold',
                            color: '#2c3e50'
                        }}>{value}</span>
                        {isUpdated && (
                            <IconButton
                                size="small"
                                onClick={(event) => cancelUpdate(event, data.id)}
                                sx={{
                                    color: '#e74c3c',
                                    '&:hover': {
                                        backgroundColor: 'rgba(231, 76, 60, 0.1)',
                                    },
                                    transition: 'all 0.2s ease'
                                }}
                            >
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        )}
                    </div>
                )
            }
        },
    ]
    function createData(
        id,
        firstName,
        lastName,
        projectName,
        invoiceNumber,
        invoiceDate,
        dueDate,
        paymentStatus,
        grandTotal,
        amountDue
    ) {
        const clientName = firstName + "\u00a0" + lastName;
        return {
            id,
            clientName,
            projectName,
            invoiceNumber,
            grandTotal,
            invoiceDate,
            dueDate,
            paymentStatus,
            amountDue
        }
    }
    useEffect(() => {
        setRows(
            props.response?.map((row) =>
                createData(
                    row.id,
                    row.clientFirstName,
                    row.clientLastName,
                    row.projectName,
                    row.invoiceNumber,
                    row.invoiceDate,
                    row.dueDate,
                    row.paymentStatus,
                    row.grandTotal,
                    row.amountDue
                )
            )
        );
    }, [props.response]);

    const handleFilterChange = (filterName, filterValue) => {
        setActiveFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: filterValue
        }));
    }
    const handleClearAllFilters = () => {
        props.setClientFirstNameFilter("");
        props.setClientLastNameFilter("");
        props.setProjectNameFilter("");
        props.setDueDateFromFilter("");
        props.setDueDateToFilter("");
        props.setInvoiceDateFromFilter("");
        props.setInvoiceDateToFilter("");
        props.setPaidDateFromFilter("");
        props.setPaidDateToFilter("");
        props.setIsActiveFilter(true);
        props.setIsPaymentStatusFilter("");
        props.setIsFilterInvoice(props.isFilterInvoice);

        const isVisible = checkFilter();
        props.setIsActiveFilter(isVisible);

        if (isVisible) {
            props.setIsFilterSelected(true);
        } else {
            if (props.filterByInvoiceTypeData !== '1') {
                props.setIsFilterSelected(true);
            } else {
                props.setIsFilterSelected(isVisible);
            }
        }

        props.getAllInvoices();
        const activeFilters = getActiveFiltersSummary();
        props.onFiltersApplied(activeFilters);
    };

    const checkFilter = () => {
        let check = false;
        if (
            props.clientFirstNameFilter !== "" ||
            props.clientLastNameFilter !== "" ||
            props.projectNameFilter !== "" ||
            props.invoiceDateFromFilter !== "" ||
            props.dueDateFromFilter !== "" ||
            props.paidDateFromFilter !== "" ||
            props.isActiveFilter !== true ||
            props.isPaymentStatusFilter !== ""
        ) {
            props.setIsFilterSelected(false);
            check = true;
        } else {
            check = false;
        }
        return check;
    };

    const getActiveFiltersSummary = () => {
        let activeFilters = [];

        if (props.clientFirstNameFilter) {
            activeFilters.push(`Client First Name: ${props.clientFirstNameFilter}`);
        }
        if (props.clientLastNameFilter) {
            activeFilters.push(`Client Last Name: ${props.clientLastNameFilter}`);
        }
        if (props.projectNameFilter) {
            activeFilters.push(`Project Name: ${props.projectNameFilter}`);
        }
        if (props.invoiceDateFromFilter) {
            activeFilters.push(`Invoice Date From: ${props.invoiceDateFromFilter}`);
        }
        if (props.invoiceDateToFilter) {
            activeFilters.push(`Invoice Date To: ${props.invoiceDateToFilter}`);
        }
        if (props.dueDateFromFilter) {
            activeFilters.push(`Due Date From: ${props.dueDateFromFilter}`);
        }
        if (props.dueDateToFilter) {
            activeFilters.push(`Due Date To: ${props.dueDateToFilter}`);
        }
        if (props.paidDateFromFilter) {
            activeFilters.push(`Paid Date From: ${props.paidDateFromFilter}`);
        }
        if (props.paidDateToFilter) {
            activeFilters.push(`Paid Date To: ${props.paidDateToFilter}`);
        }
        if (props.isActiveFilter !== true) {
            activeFilters.push(`Status: ${props.isActiveFilter ? 'Active' : 'Inactive'}`);
        }
        if (props.isPaymentStatusFilter) {
            activeFilters.push(`Payment Status: ${props.isPaymentStatusLabel}`);
        }

        return activeFilters;
    };
    const addUpdatedStatus = (id, value, invoiceData) => {
        const updatedInvoice = rows.find(row => row.id === id);
        const paymentStatusId = props.getPaymentStatus.find(status => status.label === value)?.value;

        const invoiceDataForUpdate = {
            Id: updatedInvoice.id,
            Invoice: updatedInvoice.invoiceNumber,
            ProjectName: updatedInvoice.projectName,
            InvoiceAmount: updatedInvoice.grandTotal,
            AmountDue: updatedInvoice.amountDue,
            PaymentStatus: value,
            PaymentStatusId: paymentStatusId,
            AmountReceived: '',
            comment: ''
        };

        if (value === "FULLY PAID" || value === "PARTIALLY PAID") {
            setPendingStatusUpdates(prev => [...prev, invoiceDataForUpdate]);
            setSelectedInvoicesForUpdate([invoiceDataForUpdate]);
            setShowUpdateStatusPopup(true);
        }

        // Update both arrays regardless of the payment status
        setUpdateStatusBodyArray((prev) => {
            const newPrev = prev.filter(item => item.id !== id);
            return [...newPrev, {
                id: id,
                projectID: id,
                paymentStatus: value,
                paymentStatusId: paymentStatusId
            }];
        });
        setLocallyUpdatedInvoices((prev) => {
            const newPrev = prev.filter(item => item.Id !== id);
            return [...newPrev, invoiceDataForUpdate];
        });

        // Update the rows state to reflect the change immediately
        setRows((prevRows) => prevRows.map((row) =>
            row.id === id ? { ...row, paymentStatus: value } : row
        ));

    };

    const handleLocalSave = (updatedInvoices) => {
        setLocallyUpdatedInvoices(prev => [...prev, ...updatedInvoices]);
        setRows(prevRows => {
            return prevRows.map(row => {
                const updatedInvoice = updatedInvoices.find(invoice => invoice.Id === row.id);
                if (updatedInvoice) {
                    return {
                        ...row,
                        paymentStatus: updatedInvoice.PaymentStatus,
                        amountDue: parseFloat(row.grandTotal) - parseFloat(updatedInvoice.AmountReceived)
                    };
                }
                return row;
            });
        });
    };


    const multiRowUpdateInvoiceStatus = () => {
        updateStatusBodyArray.map((item) => {
            props.setProjectID(item.id);
            props.setUpdateStatusBody(((prevObject) => ({
                ...prevObject,
                id: item.id,
                paymentStatus: item.paymentStatus,
            })))
        })
        setUpdateStatusBodyArray([]);
        setIsTableEditable(false);
    }
    useEffect(() => {
        if ((props.projectID == props.updateStatusBody.id) && props.updateStatusBody?.paymentStatus) {
            props.updateInvoiceStatus();
        }
    }, [props.projectID, props.updateStatusBody])

    const cancelUpdate = (event, id) => {
        event.stopPropagation();
        setLocallyUpdatedInvoices((prev) => prev.filter((item) => item.Id !== id));
        setUpdateStatusBodyArray((prev) => prev.filter((item) => item.id !== id));

        // Reset the row to its original state
        setRows((prevRows) => prevRows.map((row) => {
            if (row.id === id) {
                const originalRow = props.response.find((item) => item.id === id);
                return createData(
                    originalRow.id,
                    originalRow.clientFirstName,
                    originalRow.clientLastName,
                    originalRow.projectName,
                    originalRow.invoiceNumber,
                    originalRow.invoiceDate,
                    originalRow.dueDate,
                    originalRow.paymentStatus,
                    originalRow.grandTotal,
                    originalRow.amountDue
                );
            }
            return row;
        }));
    }

    const handleUpdateMultipleInvoiceStatus = (updatedInvoices = null) => {
        setIsLoading(true);
        let payload = [];

        const getPaymentStatusLabel = (statusId) => {
            const status = props.getPaymentStatus.find(s => s.value === statusId);
            return status ? status.label : '';
        };

        const isPartiallyOrFullyPaid = (status) => {
            return status === 'PARTIALLY PAID' || status === 'FULLY PAID';
        };

        // Process data from the popup (updatedInvoices)
        if (updatedInvoices && updatedInvoices.length > 0) {
            updatedInvoices.forEach((invoice) => {
                const paymentStatus = getPaymentStatusLabel(invoice.PaymentStatusId);
                payload.push({
                    invoiceId: invoice.Id,
                    paymentStatus: paymentStatus,
                    amountReceived: invoice.AmountReceived,
                    comment: invoice.comment
                });
            });
        }

        // Process data from locallyUpdatedInvoices
        if (locallyUpdatedInvoices && locallyUpdatedInvoices.length > 0) {
            locallyUpdatedInvoices.forEach((item) => {
                const paymentStatus = getPaymentStatusLabel(item.PaymentStatusId);
                if (!isPartiallyOrFullyPaid(paymentStatus) || !updatedInvoices) {
                    payload.push({
                        invoiceId: item.Id,
                        paymentStatus: paymentStatus,
                        amountReceived: item.AmountReceived || '',
                        comment: item.comment || ''
                    });
                }
            });
        }

        // Remove duplicates, prioritizing data from updatedInvoices (popup) for partially/fully paid invoices
        const uniquePayload = Object.values(payload.reduce((acc, curr) => {
            if (!acc[curr.invoiceId] || isPartiallyOrFullyPaid(curr.paymentStatus)) {
                acc[curr.invoiceId] = curr;
            }
            return acc;
        }, {}));

        if (uniquePayload.length === 0) {
            setIsLoading(false);
            setAlertPopupMessage('No updates to process');
            setAlertPopupWindow({ is: true, type: 'warning' });
            return;
        }

        updateMultipleInvoiceStatus(uniquePayload)
            .then((response) => {
                setUpdateStatusBodyArray([]);
                setLocallyUpdatedInvoices([]);
                setIsTableEditable(false);
                props.getAllInvoices();

                setAlertPopupMessage('Invoice status updated successfully');
                setAlertPopupWindow({ is: true, type: 'info' });
            })
            .catch((error) => {
                setAlertPopupMessage(error.response?.data.message);
                setAlertPopupWindow({ is: true, type: 'error' });
            })
            .finally(() => {
                setIsLoading(false);
                setShowUpdateStatusPopup(false);
            });
    };

    return (
        <>
            {isLoading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {alertPopupWindow.is && (
                <PopupMessage
                    alertPopupWindow={alertPopupWindow}
                    setAlertPopupWindow={setAlertPopupWindow}
                    alertPopupMessage={alertPopupMessage}
                    setAlertResponse={setAlertResponse}
                />
            )}
            <div className="inner-content">
                <div className='manage_content' >
                    <div className="content_subtitle">
                        <p>List of Invoices</p>
                    </div>
                    <div className="box-functional_elements">
                        <div className="functional_elements" >
                            <div onClick={props.addInvoice}>
                                <AddContentButton />
                            </div>
                        </div>
                        <div className="functional_elements" >
                            <div onClick={() => {
                                props.setPage(0);
                                props.filterInvoice();
                            }}
                                className="filterDiv">
                                <Badge
                                    invisible={!props.isFilterSelected}
                                    size="lg"
                                >
                                    <FilterContentButton />
                                </Badge>
                            </div>
                        </div>
                        <div className="functional_elements" style={{ marginTop: "4px" }} >
                            {
                                (isTableEditable || locallyUpdatedInvoices.length > 0) &&
                                <button onClick={() => handleUpdateMultipleInvoiceStatus()}>Update Status</button>
                            }
                        </div>
                        <div className='functional_elements' style={{ marginTop: "4px" }}>
                            {
                                (!isTableEditable) && <button onClick={(e) => { setIsTableEditable(true) }}>Edit</button>
                            }
                        </div>
                    </div>
                    <div className='dropdown_box'>
                        <div>
                            <FilterByInvoiceType
                                className="dropdown_box"
                                invoiceType={props.invoiceType}
                                setFilterByInvoiceTypeData={props.setFilterByInvoiceTypeData}
                                isFilterSelected={props.isFilterSelected}
                                setIsFilterSelected={props.setIsFilterSelected}
                                setIsActiveFilter={props.setIsActiveFilter}
                                isActiveFilter={props.isActiveFilter}
                            />
                        </div>

                    </div>
                    <div className='dropdown_label'>
                        Invoice Type Filter
                    </div>
                </div>
                {props.activeFilters.length > 0 && (
                    <div className="active-filters">
                        <strong>Active Filters:</strong>
                        {props.activeFilters.map((filter, index) => (
                            <span key={index} className="filter-item">
                                {filter}
                                {index < props.activeFilters.length - 1 && <span className="separator">x</span>}
                            </span>
                        ))}
                        <div className="functional_elements">
                            <button onClick={handleClearAllFilters}>Clear All</button>
                        </div>
                    </div>
                )}
                <div className='table-outer_content'>
                    <Table
                        columns={columns}
                        rows={rows}
                        totalCount={props?.totalCount}
                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        handleChangePage={props.handleChangePage}
                        handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                        function={props.getInvoiceById}
                        normalData={true}
                        setSortOrder={props.setSortOrder}
                        sortOrder={props.sortOrder}
                        setsortInvoiceData={props.setsortInvoiceData}
                        tableTitle={tableTitle}
                        sortBy={props.sortBy}
                        setSortBy={props.setSortBy}
                        sortInvoiceData={props.sortInvoiceData}
                        handleInvoiceCheck={props.handleInvoiceCheck}
                        projectID={props.projectID}
                        getPaymentStatus={props.getPaymentStatus}
                        handlePaymentStatusChange={props.handlePaymentStatusChange}
                        isTableEditable={isTableEditable}
                        isPagination={true}
                        locallyUpdatedInvoices={locallyUpdatedInvoices}
                    />
                </div>
            </div>
            {showUpdateStatusPopup && (
                <UpdateStatusPopup
                    showPopUp={showUpdateStatusPopup}
                    setShowPopUp={setShowUpdateStatusPopup}
                    selectedInvoiceList={selectedInvoicesForUpdate}
                    updateInvoiceStatus={handleUpdateMultipleInvoiceStatus}
                    paymentStatus={props.getPaymentStatus}
                    isManualTransaction={true}
                    getAllPendingInvoices={props.getAllInvoices}
                    onLocalSave={handleLocalSave}
                    useLocalSave={true}
                    isPaymentStatusEditable={false}
                />
            )}
        </>
    )
}
