import React, { useState, useEffect } from 'react'
import AddContentButton from '../../../components/Buttons/AddContent';
import FilterContentButton from '../../../components/Buttons/FilterContent';
import Table from '../../../components/Table'
import FilterByInvoiceType from '../FilterByInvoiceType';
import Badge from '@mui/joy/Badge';
import MenuItem from '@mui/material/MenuItem';
import Select from 'react-select';
import MuiSelect from '@mui/material/Select';
import { LuChevronUp } from "react-icons/lu";
import { LuChevronDown } from "react-icons/lu";
import { LuChevronsUpDown } from "react-icons/lu";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { updateMultipleInvoicePaymentStatus, updateWeeklyInvoiceDesc } from '../../../utils/api-helper';
import PopupMessage from '../InvoiceAlertPopupMessage';
import EditDetails from '../EditDetails';
import { MdCancel } from "react-icons/md";
export default function GetAllWeeklyInvoice(props) {
    const [rows, setRows] = useState([]);
    const [tableTitle, setTableTitle] = useState("listOfInvoices");
    const [activeFilters, setActiveFilters] = useState({});
    const [isTableEditable, setIsTableEditable] = useState(true);
    const [updateStatusBodyArray, setUpdateStatusBodyArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [alertPopupWindow, setAlertPopupWindow] = useState({ is: false, type: null });
    const [alertPopupMessage, setAlertPopupMessage] = useState('');
    const [alertResponse, setAlertResponse] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [rowData, setRowData] = useState({});
    const [open, setOpen] = useState(false);
    const [invoiceRows, setInvoiceRows] = useState([]);

    const formatHeaderName = (fieldName) => {
        // Split the field name by capital letters
        const result = fieldName.replace(/([A-Z])/g, ' $1');
        // Capitalize the first letter of the result
        const formattedName = result.charAt(0).toUpperCase() + result.slice(1);
        return formattedName;
    };
    const handleSortClick = (order, columnId) => {

        if (order === props.sortOrder) {
            props.setsortInvoiceData(columnId);
            props.setSortOrder(order == 'ASC' ? 'DESC' : order == 'DESC' ? null : 'ASC');
        } else {
            props.setsortInvoiceData(columnId);
            props.setSortOrder(order);
        }
    };

    const getSortIcon = (column) => {
        if (column === props.sortInvoiceData && props.sortOrder === 'ASC') {
            return <LuChevronUp style={{ fontSize: "16px" }} />
        } else if (column === props.sortInvoiceData && props.sortOrder === 'DESC') {
            return <LuChevronDown style={{ fontSize: "16px" }} />
        } else {
            return <LuChevronsUpDown style={{ fontSize: "16px" }} />;
        }
    };
    const columns = [
        {
            field: "projectName", editable: false, flex: 1, tooltipField: "projectName",
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>
                        {/* {getSortIcon(column.colId)} */}
                    </div>
                )
            }
        },
        {
            field: "rate", editable: false, flex: 0.7, cellClass: "ag-cell-right",
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>
                        {/* {getSortIcon(column.colId)} */}
                    </div>
                )
            },
            cellRenderer: (params) => {
                const rate = params.value;
                return `$${rate}`;
            },
        },

        {
            field: "description", editable: false, tooltipField: "description", flex: 2,
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>
                        {/* {getSortIcon(column.colId)} */}
                    </div>
                )
            }
        },
        {
            field: "hoursOfWeek", editable: false, headerName: 'Hours', flex: 0.8,
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.headerName)}</span>
                        {/* {getSortIcon(column.colId)} */}
                    </div>
                )
            }
        },
        {
            field: "remarks", editable: false, flex: 2,
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>
                        {/* {getSortIcon(column.colId)} */}
                    </div>
                )
            }
        },
        {
            field: "amount", editable: false, flex: 1,
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>
                        {/* {getSortIcon(column.colId)} */}
                    </div>
                )
            },
            cellRenderer: (params) => {
                const amount = params.value;
                return amount > 0 ? `$${amount}` : '';
            },
        },
        {
            field: "noBill",
            headerName: "No Bill",
            flex: 0.8,
            editable: false,
            cellRendererFramework: (params) => {
                const isChecked = (params?.data?.remarks == '-' && params?.data?.description == '-' && params?.data?.hoursOfWeek == '-');
                const isEdited = updateStatusBodyArray.some(item => item.projectId === params.data.id)
                const handleCheckboxChange = (e) => {
                    e.stopPropagation();
                    const checked = e.target.checked;


                    if (checked) {
                        // Add the entry to updateStatusBodyArray
                        setUpdateStatusBodyArray((prevArray) => [
                            ...prevArray,
                            {
                                projectId: params.data.id,
                                projectName: params.data.projectName,
                                description: "0",
                                hoursOfWeek: "0",
                                remarks: "0",
                                isUpWorkProject: true,
                                rate: params.data.rate,
                                startDate: props.invoiceDateFromFilter,
                                endDate: props.invoiceDateToFilter
                            },
                        ]);
                    } else {
                        // Remove the entry from updateStatusBodyArray
                        setUpdateStatusBodyArray((prevArray) =>
                            prevArray.filter((item) => item.projectId !== params.data.id)
                        );
                    }
                };

                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }} >
                        <input
                            type="checkbox"
                            checked={isChecked}
                            disabled={params.data.invoiceGenerated}
                            onChange={e => { }}
                            onClick={(e) => {
                                // e.stopPropagation();
                                handleCheckboxChange(e)
                            }
                            }
                        />
                        {isEdited && <button style={{ fontSize: "16px", color: 'red', border: 'none', backgroundColor: '#fff' }} onClick={() => {
                            setUpdateStatusBodyArray((prevArray) =>
                                prevArray.filter((item) => item.projectId !== params.data.id)
                            );
                        }}><MdCancel /></button>}
                    </div>
                );
            },
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.headerName || "No Bill / Invoice Generated")}</span>
                    </div>
                );
            }
        }

    ];

    const invoiceColumns = [

        {
            field: "projectName", sortable: false,
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>

                    </div>
                )
            }
        },
        {
            field: "invoiceNumber", sortable: false,
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>

                    </div>
                )
            }
        },
        {
            field: "invoiceDate", sortable: false,
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>

                    </div>
                )
            }
        },
        {
            field: "dueDate", sortable: false,
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>

                    </div>
                )
            }
        },
        {
            field: "paymentStatus", sortable: false,
            headerComponentFramework: ({ column }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatHeaderName(column.colDef.field)}</span>

                    </div>
                )
            },
        },
        {
            field: "grandTotal", headerName: "Invoice\u00a0Amount", sortable: false,
        },
    ];


    function createData(
        id,
        projectName,
        rate,
        isUpWorkProject,
        description,
        hoursOfWeek,
        remarks,
        invoiceGenerated

    ) {
        return {
            id,
            projectName,
            rate: rate,
            isUpWorkProject,
            description: description == 0 ? '-' : description,
            hoursOfWeek: hoursOfWeek == 0 ? '-' : hoursOfWeek,
            remarks: remarks == 0 ? '-' : remarks,
            invoiceGenerated,
            amount: Number(hoursOfWeek) * Number(rate)
        }
    }

    function createInvoiceData(
        id,
        projectName,
        invoiceNumber,
        invoiceDate,
        dueDate,
        paymentStatus,
        grandTotal
    ) {
        return {
            id,
            projectName,
            invoiceNumber,
            grandTotal,
            invoiceDate,
            dueDate,
            paymentStatus
        }
    }


    useEffect(() => {
        // Initialize rows from props.response
        console.log('updatestatusbody', updateStatusBodyArray)
        console.log(props.upworkInvoiceGenerated)
        const initialRows = props.response?.map((row) =>
            createData(
                row.id,
                row.projectName,
                row?.rate || undefined,
                row.isUpWorkProject ? 'Yes' : 'No',
                row?.upworkDetails?.[0]?.description || undefined,
                row?.upworkDetails?.[0]?.hoursOfWeek == 0 ? 0 : row?.upworkDetails?.[0]?.hoursOfWeek,
                row?.upworkDetails?.[0]?.remarks || undefined,
                row?.invoiceGenerated
            )
        ) || [];


        // If there are updates, merge them into the rows
        if (updateStatusBodyArray.length > 0) {
            const updatedRows = initialRows.map((row) => {
                const updatedItem = updateStatusBodyArray.find(item => item.projectId === row.id);
                if (updatedItem) {
                    // Replace the row with updated data
                    return createData(
                        updatedItem.projectId,
                        updatedItem.projectName,
                        updatedItem.rate,
                        updatedItem.isUpWorkProject ? 'Yes' : 'No',
                        updatedItem.description,
                        updatedItem.hoursOfWeek,
                        updatedItem.remarks,
                        updatedItem.invoiceGenerated
                    );
                }
                return row; // Keep the original row if no match
            });

            setRows(updatedRows);
        } else {
            setRows(initialRows); // No updates, just set initial rows
        }
    }, [props.response, updateStatusBodyArray]);


    useEffect(() => {
        console.log(props.invoiceResp)
        const invoiceList = props.invoiceResp.map((item) => {
            return createInvoiceData(item.id, item.projectName, item.invoiceNumber, item.invoiceDate, item.dueDate, item.paymentStatus, item.grandTotal)
        });
        console.log("invoiceList", invoiceList)
        setInvoiceRows(invoiceList);
    }, [props.invoiceResp])


    const addUpdatedStatus = (id, value) => {
        setUpdateStatusBodyArray(prev => {
            const newItem = {
                projectId: id,  // Always store projectId
                isUpWorkProject: value.isUpWorkProject,
                projectName: value.projectName,
                description: value.description,
                hoursOfWeek: value.hoursOfWeek,
                remarks: value.remarks,
                rate: value.rate,
                startDate: props.invoiceDateFromFilter,
                endDate: props.invoiceDateToFilter
            };

            // Check if this projectId already exists in the array
            const existingItemIndex = prev.findIndex(item => item.projectId === id);

            if (existingItemIndex > -1) {
                // If found, replace the old item with the new one
                const newPrev = [...prev];
                newPrev[existingItemIndex] = newItem;
                return newPrev;
            } else {
                // If not found, add the new item to the array
                return [...prev, newItem];
            }
        });

    };


    useEffect(() => {
        if ((props.projectID == props.updateStatusBody.id) && props.updateStatusBody?.paymentStatus) {
            props.updateInvoiceStatus();
        }
    }, [props.projectID, props.updateStatusBody])

    const handleUpdateMultipleInvoiceStatus = () => {
        console.log('updateStatusBodyArray', updateStatusBodyArray);
        updateStatusBodyArray.map(item => {
            item.isUpWorkProject = item.isUpWorkProject === 'Yes';
            item.rate = item.rate;
        });
        setIsLoading(true);

        updateWeeklyInvoiceDesc(updateStatusBodyArray).then((resp) => {
            props.getAllInvoices();
            setUpdateStatusBodyArray([]);
            setIsTableEditable(true);
        }).catch((error) => {
            setAlertPopupMessage(error.response?.data.message);
            setAlertPopupWindow({ is: true, type: 'error' });
        }).finally(() => {
            setIsLoading(false);
        });

    };

    const openEditModal = (rowData) => {
        console.log(rowData);
        if (!rowData.invoiceGenerated) {
            setRowData(rowData);
            setModalIsOpen(true);
        }

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (confirm) => {
        if (confirm) {
            // Handle submit logic here
            handleUpdateMultipleInvoiceStatus()
        }
        setOpen(false);
    };

    const handleShowInvoices = () => {
        props.setShowInvoices(true)
    }

    const handleShowProjects = () => {
        props.setShowInvoices(false);
    }

    return (
        <>
            {isLoading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {alertPopupWindow.is && (
                <PopupMessage
                    alertPopupWindow={alertPopupWindow}
                    setAlertPopupWindow={setAlertPopupWindow}
                    alertPopupMessage={alertPopupMessage}
                    setAlertResponse={setAlertResponse}
                />
            )}
            <div className="inner-content">
                <div className='manage_content' >
                    <div style={{ paddingTop: '10px' }} className="content_subtitle">
                        <p>Work Week</p>
                    </div>
                    <div className="box-functional_elements">

                        <div className='functional_elements' style={{ marginTop: "4px" }}>
                            {
                                (isTableEditable && props.isInvoiceGenerated && !props.showInvoices) && <button
                                    onClick={handleShowInvoices}
                                    style={{
                                        backgroundColor: '#fff',  // Gray when disabled
                                        color: 'var(--navyblue1)',               // Adjust text color when disabled
                                        cursor: 'pointer',     // Change cursor style when disabled
                                        border: '2px solid var(--navyblue1)'
                                    }}
                                >
                                    View Invoices
                                </button>

                            }

                            {
                                (isTableEditable && props.isInvoiceGenerated && props.showInvoices) && <button
                                    // disabled={props.upworkInvoiceGenerated}
                                    onClick={handleShowProjects}
                                    style={{
                                        backgroundColor: '#fff',  // Gray when disabled
                                        color: 'var(--navyblue1)',               // Adjust text color when disabled
                                        cursor: 'pointer',     // Change cursor style when disabled
                                        border: '2px solid var(--navyblue1)'
                                    }}
                                >
                                    Show Projects
                                </button>

                            }
                        </div>

                        <div className='functional_elements' style={{ marginTop: "4px" }}>
                            {
                                (isTableEditable && !props.showInvoices) && <button
                                    disabled={props.upworkInvoiceGenerated}
                                    onClick={handleClickOpen}
                                    style={{
                                        backgroundColor: props.upworkInvoiceGenerated ? 'gray' : 'var(--navyblue1)',  // Gray when disabled
                                        color: props.upworkInvoiceGenerated ? '#ccc' : '#fff',               // Adjust text color when disabled
                                        cursor: props.upworkInvoiceGenerated ? 'not-allowed' : 'pointer'     // Change cursor style when disabled
                                    }}
                                    title={props.upworkInvoiceGenerated ? 'Invoice generated' : ''}        // Tooltip when disabled
                                >
                                    Submit Upwork Hours
                                </button>

                            }
                        </div>
                    </div>


                    <div style={{ marginTop: 0, marginLeft: 10 }} className="financial-year-select">
                        <Select
                            options={props.pastFourWeeks}
                            value={props.currentWeek}
                            onChange={props.handleWeekChange}
                            placeholder="Select Weeks"
                            classNamePrefix="custom-select"
                        />
                    </div>
                </div>

                <div className='table-outer_content' style={{
                    overflowX: 'auto', maxWidth:'100%'
                }}>
                    {!props.showInvoices && <Table
                        columns={columns}
                        rows={rows}
                        totalCount={props?.totalCount}
                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        handleChangePage={props.handleChangePage}
                        handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                        function={() => { }}
                        normalData={true}
                        setSortOrder={props.setSortOrder}
                        sortOrder={props.sortOrder}
                        setsortInvoiceData={props.setsortInvoiceData}
                        tableTitle={tableTitle}
                        sortBy={props.sortBy}
                        setSortBy={props.setSortBy}
                        sortInvoiceData={props.sortInvoiceData}
                        handleInvoiceCheck={props.handleInvoiceCheck}
                        projectID={props.projectID}
                        getPaymentStatus={props.getPaymentStatus}
                        handlePaymentStatusChange={props.handlePaymentStatusChange}
                        isTableEditable={isTableEditable}
                        isPagination={false}
                        fromPage='WeeklyInvoice'
                        onCellClick={openEditModal}
                    />}

                    {props.showInvoices && <Table
                        columns={invoiceColumns}
                        rows={invoiceRows}
                        totalCount={props?.totalCount}
                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        handleChangePage={props.handleChangePage}
                        handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                        function={props.getInvoiceById}
                        normalData={true}
                        setSortOrder={props.setSortOrder}
                        sortOrder={props.sortOrder}
                        setsortInvoiceData={props.setsortInvoiceData}
                        tableTitle={tableTitle}
                        sortBy={props.sortBy}
                        setSortBy={props.setSortBy}
                        sortInvoiceData={props.sortInvoiceData}
                        handleInvoiceCheck={props.handleInvoiceCheck}
                        projectID={props.projectID}
                        getPaymentStatus={props.getPaymentStatus}
                        handlePaymentStatusChange={props.handlePaymentStatusChange}
                        isTableEditable={true}
                        isPagination={false}
                        fromPage='WeeklyInvoice'
                    />}
                </div>

                <EditDetails
                    open={modalIsOpen}
                    onClose={() => setModalIsOpen(false)}
                    rowData={rowData}
                    addUpdatedStatus={addUpdatedStatus}
                />


                {/* Confirmation Dialog Box */}
                <div>
                    <Dialog
                        open={open}
                        onClose={() => handleClose(false)}
                        aria-labelledby="confirmation-dialog-title"
                        aria-describedby="confirmation-dialog-description"
                    >
                        <DialogTitle id="confirmation-dialog-title">
                            {"Confirm Submission"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="confirmation-dialog-description">
                                Hours submitted cannot be changed. Are you sure you want to submit?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose(false)} sx={{
                                color: 'var(--navyblue1)', // Custom color
                                borderColor: 'var(--navyblue1)',
                                '&:hover': {
                                    borderColor: 'var(--navyblue1)', // Custom color on hover
                                },
                            }}>
                                Cancel
                            </Button>
                            <Button onClick={() => handleClose(true)} sx={{
                                backgroundColor: 'var(--navyblue3)', // Custom color
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'var(--navyblue2)',
                                    color: 'white',
                                },
                            }} >
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </>
    )
}
