import "./App.css";
import RoutingPage from "./Router";
import { useEffect } from "react";
import { createBrowserHistory } from "history";
import emitter from "./utils/Emitter";
import { useNavigate } from "react-router-dom";

function App() {
  let history = createBrowserHistory();
  const navigate = useNavigate();

  useEffect(() => {
    emitter.on("TOKEN_EXPIRED", (isExpired) => {
      return navigate("/login");
    });

    return () => emitter.off("TOKEN_EXPIRED");
  }, [navigate]);
  return (
    <>
      <RoutingPage history={history} />
    </>
  );
}
export default App;
