import React, { useState, useEffect, memo } from 'react';
import { Modal, Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'center',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    border: '1px solid #1a3e72', // Add border to text field
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
});

const StyledSelect = styled(Select)({
  borderRadius: 8,
  border: '1px solid #1a3e72', // Add border to select dropdown
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E0E3E7',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B2BAC2',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#6F7E8C',
  },
});

const convertAmountStringWithCurrencyToNumber = (amount) => {
  let cleanedAmount = amount.replace(/[₹$€£\s]/g, "");
  if (cleanedAmount.includes(',') && !cleanedAmount.includes('.')) {
    cleanedAmount = cleanedAmount.replace(',', '.');
  } else {
    cleanedAmount = cleanedAmount.replace(/[.,]/g, "");
    if (cleanedAmount.length > 2) {
      cleanedAmount = cleanedAmount.slice(0, -2) + '.' + cleanedAmount.slice(-2);
    }
  }
  return parseFloat(cleanedAmount);
};

const formatCurrency = (amount, originalFormat) => {
  const useCommaAsDecimal = originalFormat.includes(',') && !originalFormat.includes('.');
  const currencySymbol = originalFormat.match(/[₹$€£]/)?.[0] || '';

  if (useCommaAsDecimal) {
    return currencySymbol + amount.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return currencySymbol + amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};



const StatusTable = memo(({ invoices, handlePaymentStatusChange, handleCommentChange, handleAmountReceivedChange, errors, isPaymentStatusEditable }) => {
  return (
    <TableContainer component={Paper} sx={{
      overflow: 'auto',
      borderRadius: 4,
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      border: '1px solid #1a3e72',
    }}>
      <Table stickyHeader aria-label="invoice table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Invoice Number</StyledTableCell>
            <StyledTableCell align="right">Project Name</StyledTableCell>
            <StyledTableCell align="right">Invoice Amount</StyledTableCell>
            <StyledTableCell align="right">Amount Due</StyledTableCell>
            <StyledTableCell align="right">Amount Received</StyledTableCell>
            <StyledTableCell align="right">Payment Status</StyledTableCell>
            <StyledTableCell align="right">Reason</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice) => (
            <StyledTableRow key={invoice.Id}>
              <StyledTableCell component="th" scope="row">
                {invoice.Invoice}
              </StyledTableCell>
              <StyledTableCell align="right">{invoice.ProjectName}</StyledTableCell>
              <StyledTableCell align="right">{invoice.InvoiceAmount}</StyledTableCell>
              <StyledTableCell align="right">{invoice.AmountDue}</StyledTableCell>
              <StyledTableCell align="right">
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="text"
                  value={invoice.AmountReceived || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
                      handleAmountReceivedChange(value, invoice.Id);
                    }
                  }}
                  inputProps={{
                    inputMode: 'decimal',
                    pattern: '[0-9]*',
                  }}
                  error={errors[invoice.Id]?.amountReceived}
                  helperText={errors[invoice.Id]?.amountReceived ? "Please enter a valid amount" : ""}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                {isPaymentStatusEditable ? (
                  <FormControl fullWidth>
                    <StyledSelect
                      value={invoice.PaymentStatus || ''}
                      onChange={(e) => handlePaymentStatusChange(e.target.value, invoice.Id)}
                      displayEmpty
                      size="small"
                      error={errors[invoice.Id]?.paymentStatus}
                    >
                      <MenuItem value="">
                        <em>Select Status</em>
                      </MenuItem>
                      <MenuItem value="FULLY PAID">Fully Paid</MenuItem>
                      <MenuItem value="PARTIALLY PAID">Partially Paid</MenuItem>
                    </StyledSelect>
                  </FormControl>
                ) : (
                  <Typography>{invoice.PaymentStatus}</Typography>
                )}
              </StyledTableCell>
              <StyledTableCell align="right">
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={invoice.comment || ''}
                  onChange={(e) => handleCommentChange(e.target.value, invoice.Id)}
                  error={errors[invoice.Id]?.comment}
                  helperText={errors[invoice.Id]?.comment ? "Comment cannot be empty" : ""}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

const UpdateStatusPopup = ({
  showPopUp,
  setShowPopUp,
  selectedInvoiceList,
  transaction,
  updateInvoiceStatus,
  paymentStatus,
  isManualTransaction,
  getAllPendingInvoices,
  onLocalSave,
  isPaymentStatusEditable = true ,
  useLocalSave = false
}) => {
  const [invoices, setInvoices] = useState(selectedInvoiceList);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setInvoices(selectedInvoiceList);
  }, [selectedInvoiceList]);

  const handlePaymentStatusChange = (value, invoiceId) => {
    setInvoices(prevInvoices =>
      prevInvoices.map(invoice =>
        invoice.Id === invoiceId ? { ...invoice, PaymentStatus: value } : invoice
      )
    );
    // Clear error when user selects a status
    setErrors(prevErrors => ({
      ...prevErrors,
      [invoiceId]: { ...prevErrors[invoiceId], paymentStatus: null }
    }));
  };

  const handleCommentChange = (value, invoiceId) => {
    setInvoices(prevInvoices =>
      prevInvoices.map(invoice =>
        invoice.Id === invoiceId ? { ...invoice, comment: value } : invoice
      )
    );
    // Clear error when user starts typing
    setErrors(prevErrors => ({
      ...prevErrors,
      [invoiceId]: { ...prevErrors[invoiceId], comment: null }
    }));
  };

  const handleAmountReceivedChange = (value, invoiceId) => {
    setInvoices(prevInvoices =>
      prevInvoices.map(invoice =>
        invoice.Id === invoiceId ? { ...invoice, AmountReceived: value } : invoice
      )
    );
    // Clear error when user enters a value
    setErrors(prevErrors => ({
      ...prevErrors,
      [invoiceId]: { ...prevErrors[invoiceId], amountReceived: null }
    }));
  };

  const validateInvoices = () => {
    const newErrors = {};
    let isValid = true;

    invoices.forEach(invoice => {
      newErrors[invoice.Id] = {};

      if (!invoice.comment || invoice.comment.trim() === '') {
        newErrors[invoice.Id].comment = true;
        isValid = false;
      }

      if (!invoice.PaymentStatus) {
        newErrors[invoice.Id].paymentStatus = true;
        isValid = false;
      }

      if (!invoice.AmountReceived || isNaN(parseFloat(invoice.AmountReceived))) {
        newErrors[invoice.Id].amountReceived = true;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = () => {
    if (!validateInvoices()) {
      setError("Please fill in all required fields with valid values.");
      return;
    }

    const updatedInvoices = invoices.map(invoice => {
      // Find the matching payment status
      const paymentStatusOption = paymentStatus.find(status =>
        status.value === invoice.PaymentStatus || status.label === invoice.PaymentStatus
      );

      if (!paymentStatusOption) {
        console.error(`No matching payment status found for: ${invoice.PaymentStatus}`);
        return null;
      }

      return {
        ...invoice,
        AmountReceived: parseFloat(invoice.AmountReceived || 0).toFixed(2),
        comment: invoice.comment,
        paymentStatusId: paymentStatusOption.id,
        PaymentStatus: paymentStatusOption.label, // Ensure we're using the label
        isManualTransaction: isManualTransaction,
      };
    }).filter(invoice => invoice !== null);

    if (updatedInvoices.length === 0) {
      console.error("No valid invoices to update");
      setError("No valid invoices to update. Please check the payment statuses.");
      return;
    }

    if (useLocalSave) {
      onLocalSave(updatedInvoices);
      setShowPopUp(false);
    } else {
      if (typeof updateInvoiceStatus === 'function') {
        const result = updateInvoiceStatus(updatedInvoices);

        const finishUpdate = () => {
          setShowPopUp(false);
          if (typeof getAllPendingInvoices === 'function') {
            getAllPendingInvoices();
          }
        };

        if (result && typeof result.then === 'function') {
          result
            .then(finishUpdate)
            .catch(error => {
              console.error('Error updating invoice statuses:', error);
              setError("Failed to update invoice statuses. Please try again.");
            });
        } else {
          finishUpdate();
        }
      } else {
        setError('Update function is not available');
      }
    }
  };

  const updatedAmount = transaction ? Math.max(transaction.transactionAmount - invoices.reduce((sum, invoice) => sum + (parseFloat(invoice.AmountReceived) || 0), 0), 0) : 0;

  return (
    <Modal
      open={showPopUp}
      onClose={() => setShowPopUp(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: '1200px',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        p: 4,
        overflowY: 'auto',
        overflowX: 'hidden',
        border: '2px solid #1a3e72',
      }}>
        <Typography id="modal-modal-title" variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#1a3e72' }}>
          Update Invoice Payment Status
        </Typography>
        {transaction && (
          <Box mb={3} p={2} bgcolor="#f0f4f8" borderRadius={2}>
            <Typography variant="h6" gutterBottom sx={{ color: '#1a3e72' }}>Transaction Details</Typography>
            <Typography><strong>Date:</strong> {transaction.transactionDate}</Typography>
            <Typography><strong>Amount:</strong> {transaction.transactionAmount}</Typography>
            <Typography><strong>Details:</strong> {transaction.transactionDetails}</Typography>
            <Typography><strong>Amount Left:</strong> {updatedAmount}</Typography>
          </Box>
        )}
        <StatusTable
          invoices={invoices}
          handlePaymentStatusChange={handlePaymentStatusChange}
          handleCommentChange={handleCommentChange}
          handleAmountReceivedChange={handleAmountReceivedChange}
          errors={errors}
          isPaymentStatusEditable={isPaymentStatusEditable} 
        />

        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}

        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button
            onClick={() => setShowPopUp(false)}
            sx={{
              mr: 2,
              borderRadius: 2,
              textTransform: 'none',
              color: '#1a3e72',
              borderColor: '#1a3e72',
              '&:hover': {
                backgroundColor: '#f0f4f8',
              }
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              backgroundColor: '#1a3e72',
              '&:hover': {
                backgroundColor: '#2c5282',
              }
            }}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateStatusPopup;