import axios from "axios";
import { useNavigate } from "react-router-dom";
import emitter from "./Emitter";
import {
  getItemLocalStorage,
  setItemLocalStorage,
  setArray,
  getArray,
} from "./local-storage-helper";
import { clearAllLocalStorage } from "./local-storage-helper";
let myInterceptor;

export function getisAuth() {
  let isAuth = getItemLocalStorage("isAuth")
    ? getItemLocalStorage("isAuth")
    : false;
  return isAuth;
}
export function setisAuth(isAuth) {
  setItemLocalStorage("isAuth", isAuth ? true : false);
  
}
export let OAUTH2_CLIENT_ID = "invoice-client";
export let OAUTH2_CLIENT_PASSWORD = "tp*k3m1H&s";

// SECRETKEY = '$2a$10$nVC5JAsadUrjbv5MkmeqreM7byu4E7bJCs/GcY9aS0hrDunZbXu8u';

export const getHostURL = () => {
  return "https://devqa.clientinvoice.biz4solutions.com/invoiceclient";

  //"https://640d-137-59-64-76.ngrok.io";
  // http://52.201.232.144:8081
  // http://54.84.131.8:8081
  // https://clientinvoice.biz4solutions.com/clientinvoice // AWS server //
  // https://devqa.clientinvoice.biz4solutions.com/invoiceclient testing server
  // http://localhost:8087 // Local server //
};
export function setUserData(data) {
  setArray("user_data", data ? data : undefined);
}
export function getUserData() {
  let user_data = getArray("user_data") ? getArray("user_data") : "";
  return user_data;
}
export function getAccessToken() {
  let access_token = getItemLocalStorage("access_token")
    ? getItemLocalStorage("access_token")
    : "";
  return access_token;
}
export function getRefreshToken() {
  let refresh_token = getItemLocalStorage("refresh_token")
    ? getItemLocalStorage("refresh_token")
    : "577ce0a0-3f33-4174-9434-c8e60e3ab42c";
  return refresh_token;
}
export function setAuthToken(acces_token, refresh_token) {
  setItemLocalStorage(
    "access_token",
    acces_token ? "Bearer " + acces_token : ""
  );
  setItemLocalStorage(
    "refresh_token",
    (refresh_token = refresh_token ? refresh_token : "")
  );
}

if (!myInterceptor) {
  myInterceptor = axios.interceptors.request.use(
    function (config) {
      config.timeout = 30 * 1000; // 30 sec
      if (getAccessToken()) {
        config.headers["Authorization"] = getAccessToken();
      }
      config.headers["Accept-Language"] = "en";
      config.headers["deviceId"] = "1";
      config.headers["deviceType"] = "windows";
      config.headers["appVersion"] = "0.0.1";
      config.headers["deviceName"] = "localhost";
      config.headers["role"] = "ADMIN";
      config.headers["Bypass-Tunnel-Reminder"] = true;

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      if (
        response &&
        (response?.data?.code === 200 ||
          response?.code === 200 ||
          (response?.status === 200 &&
            response?.request.responseType === "blob"))
      ) {
        return Promise.resolve(response.data);
        //change to promise
      }
      return Promise.reject(response);
    },
    async function (error) {
      const originalRequest = error.config;
      if (
        error &&
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const access_token = await refreshAccessToken(getHostURL());
        setItemLocalStorage(
          "access_token",
          access_token ? "Bearer " + access_token : ""
        );

        return axios(originalRequest);
      } else if (
        error.message &&
        error.message.toLowerCase().indexOf("timeout") > -1
      ) {
        error.data = {};
        error.data.message =
          "Sorry. We are experiencing an error at this time. Please try again in a few minutes.";
      } else if (
        error.message &&
        error.message.toLowerCase().indexOf("network") > -1
      ) {
        error.data = {};
        error.data.message =
          "Please check your internet connection and try again.";
      } else if (error.response.status === 400) {
        console.error(error);
      }
      return Promise.reject(error);
    }
  );
}

export function refreshAccessToken(url) {
  // const basicAuthData =
  //   "Basic " + btoa(`${OAUTH2_CLIENT_ID}:${OAUTH2_CLIENT_PASSWORD}`);
  return new Promise((resolve, reject) => {
    //Calling oauth/token api with separate axios instance and config
    let instance = axios.create();
    instance
      .request({
        baseURL: url,
        url: "api/v1/auth/refreshToken",
        method: "post",
        headers: {
          // Authorization: basicAuthData,
          // "content-type": "application/x-www-form-urlencoded",
          "content-type": "application/json",
          "Accept-Language": "en",
          deviceId: "1",
          "device-type": "windows",
          appVersion: "0.0.1",
          devicename: "localhost",
        },
        data: {
          refreshToken: getRefreshToken(),
        },
      })
      .then(function (response) {
        if (response && response.data && response.status === 200) {
          clearAllLocalStorage();
          setAuthToken(response.data.access_token, response.data.refresh_token);
          setUserData(response.data.data);
          setisAuth(true);
          setItemLocalStorage("userRole", response?.data.data.role);
          // Emitter.emit("UPDATE_TOKEN", response.data);
          resolve(response.data.access_token);
          //change to promise
          // console.log("Error to be shown.");
        }
        reject(response);
      })
      .catch(function (error) {
        // BrowseHistory('/login');
        clearAllLocalStorage();
        emitter.emit("TOKEN_EXPIRED", true);
        //emitter.emit
        // let history = useNavigate();
        // history("/login");
        // console.log("oauthLogin error -->", error);
        //   Emitter.emit("LOGOUT", {});
        reject(error);
      });
  });
}

// Location Controller
export function getlocationInvoiceOriginatingAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/location/getAllLocation`);
}
// New Registration
export function registorAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/users/signup`, body);
}
// Admin Manager
export function changePasswordAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/admin/changePassword`, body);
}
export function forgetPasswordAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/admin/reset/password`, body);
}
// Client Controller
export function addClientAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/user/client/addClient`, body);
}
export function listClientTypeListAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/client/clientType/list`);
}
export function getClientByIdAPI(id) {
  return axios.get(`${getHostURL()}/api/v1/user/client/find/${id}`);
}
export function getAllClientAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/client/getAllClient`);
}
export function getAllClientListAPI(body, sort, page, size) {
  return axios.post(
    `${getHostURL()}/api/v1/user/client/list?filter=ALL&order=ASC&sort=${sort}&page=${page}&size=${size}`,
    body
  );
}
export function updateClientAPI(body) {
  return axios.put(`${getHostURL()}/api/v1/user/client/update`, body);
}
export function removeClientAPI(id) {
  return axios.put(`${getHostURL()}/api/v1/user/client/delete/client?${id}`);
}
export function enableClientAPI(id) {
  return axios.post(`${getHostURL()}/api/v1/user/client/enable/client`, id);
}

// Invoice Controller
export function addInvoiceAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/user/invoice/add`, body);
}
export function removeInvoiceAPI(id) {
  return axios.put(`${getHostURL()}/api/v1/user/invoice/delete/invoice`, id);
}
export function getInvoiceByIdAPI(id) {
  return axios.get(`${getHostURL()}/api/v1/user/invoice/find/${id}`);
}
export function getAllInvoiceAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/invoice/getAllInvoice`);
}
export function getPaymentStatusAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/invoice/invoiceStatus/list`);
}
export function getAllInvoiceListAPI(body, sort, order, page, size) {
  return axios.post(
    // `${getHostURL()}/api/v1/user/invoice/list?filter=ALL&order=ASC&sort=${sort}&order=${order}&page=${page}&size=${size}`,
    `${getHostURL()}/api/v1/user/invoice/list?filter=ALL&sort=${sort}&order=${order}&page=${page}&size=${size}`,
    body
  );
}

  export function createNullEntriesAPI(body) {
    return axios.post(
      `${getHostURL()}/api/v1/user/upwork-invoice/create-null-entries`,
      body
    );

  
}

export function getInvoiceTotalAmountAPI(body) {
  return axios.post(
    `${getHostURL()}/api/v1/user/invoice/invoiceTotalAmount`,
    body
  );
}

export function getAllDraftInvoicesListAPI(body, sort, order, page, size) {
  return axios.post(
    `${getHostURL()}/api/v1/manager/dashboard/list/draft?filter=ALL&sort=${sort}&order=${order}&page=${page}&size=${size}`,
    body
  );
}

export function getAllPendingInvoicesListAPI(body, sort, order, page, size) {
  return axios.post(
    `${getHostURL()}/api/v1/manager/dashboard/list/pending?filter=ALL&sort=${sort}&order=${order}&page=${page}&size=${size}`,
    body
  );
}

export function getAllUnpaidInvoicesListAPI(body, sort, order, page, size) {
  return axios.post(
    `${getHostURL()}/api/v1/manager/dashboard/list/unpaid?filter=ALL&sort=${sort}&order=${order}&page=${page}&size=${size}`,
    body
  );
}

export function updateInvoiceAPI(body) {
  return axios.put(`${getHostURL()}/api/v1/user/invoice/update`, body);
}
export function downloadInvoiceAPI(invoiceId, invoiceNumber) {
  return axios.get(
    `${getHostURL()}/api/v1/user/invoice/api/v1/invoice/download?invoiceId=${invoiceId}&invoiceNumber=${invoiceNumber}`,
    { responseType: "blob" }
  );
}
export function getAllInvoiceType() {
  return axios.get(`${getHostURL()}/api/v1/user/invoice/getAllInvoiceType`);
}
// Project Controller
export function addProjectAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/user/project/addProject`, body);
}
export function getProjectByIdAPI(id) {
  return axios.get(`${getHostURL()}/api/v1/user/project/find/${id}`);
}
export function getAllProjectAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/project/getAllProject`);
}
export function getAllProjectListAPI(body, sort, page, size) {
  return axios.post(
    `${getHostURL()}/api/v1/user/project/list?filter=ALL&order=ASC&sort=${sort}&page=${page}&size=${size}`,
    body
  );
}
export function removeProjectAPI(id) {
  return axios.put(`${getHostURL()}/api/v1/user/project/delete/project?${id}`);
}
export function enableProjectAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/user/project/enable/project`, body);
}
export function updateProjectAPI(body) {
  return axios.put(`${getHostURL()}/api/v1/user/project/update`, body);
}
//Project Type
export function getProjectTypeAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/projectType/getAllProjectType`);
}

// Country Controller
export function getCountryByIdAPI(id) {
  return axios.get(`${getHostURL()}/api/v1/user/country/find/${id}`);
}
export function addCountryAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/user/country/addCountry`, body);
}
export function getAllCountryAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/country/getAllCountry`);
}
export function editCountryAPI(id, body) {
  return axios.put(`${getHostURL()}/api/v1/user/country/update/${id}`, body);
}
export function deleteCountryAPI(id) {
  return axios.put(
    `${getHostURL()}/api/v1/user/country/delete/country?id=${id}`
  );
}
// State Controller
export function addStateAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/user/state/addState`, body);
}
export function getStateByIdAPI(id) {
  return axios.get(`${getHostURL()}/api/v1/user/state/find/${id}`);
}
export function getAllStateAPI(id) {
  return axios.get(`${getHostURL()}/api/v1/user/state/getAllState?id=${id}`);
}
export function deleteStateAPI(id) {
  return axios.put(`${getHostURL()}/api/v1/user/state/delete/state?id=${id}`);
}
export function updateStateAPI(id, body) {
  return axios.put(`${getHostURL()}/api/v1/user/state/update/${id}`, body);
}

// city Controller
export function getAllCitiesAPI(id) {
  return axios.get(`${getHostURL()}/api/v1/user/city/getAllCity?id=${id}`);
}
export function getCityByIdAPI(id) {
  return axios.get(`${getHostURL()}/api/v1/user/city/find/${id}`);
}
export function addCitiesAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/user/city/addCity`, body);
}
export function deleteCitiesAPI(id) {
  return axios.put(`${getHostURL()}/api/v1/user/city/delete/city?id=${id}`);
}
export function editCitiesAPI(id, body) {
  return axios.put(`${getHostURL()}/api/v1/user/city/update/${id}`, body);
}
// payment Mode Controller
export function getAllPaymentModeAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/paymentMode/getAllPaymentMode`);
}
// payment Terms Controller
export function getPaymentTermsAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/paymentTerm/getAllPaymentTerm`);
}
export function getAllUsersForAdmin(body, sort, page, size) {
  return axios.post(
    `${getHostURL()}/api/v1/manageUsers/getAllManageUser?filter=ALL&order=DESC&sort=${sort}&page=${page}&size=${size}`,
    body
  );
}
export function getAllRolesAPI() {
  return axios.get(`${getHostURL()}/api/v1/users/getAllRoles`);
}
export function createUser(body) {
  return axios.post(`${getHostURL()}/api/v1/manageUsers/signup`, body);
}
// User Controller
export function activateUserAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/activate`);
}
export function getAllUsersAPI() {
  return axios.get(`${getHostURL()}/api/v1/user/getAllUser`);
}
export function createUserAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/user/create`, body);
}
// export function logoutUserAPI() {
//     return axios.get(`${getHostURL()}/api/v1/user/logout`);
// }
export function updateUserProfileAPI(body) {
  return axios.put(`${getHostURL()}/api/v1/user/update`, body);
}

// User Identity Controller
export function logoutBlockedUserAPI() {
  return axios.delete(`${getHostURL()}/api/v1/users/blocked/logout`);
}
export function logoutAdminAPI() {
  return axios.delete(`${getHostURL()}/api/v1/users/logout`);
}
export function signupAPI(body) {
  return axios.post(`${getHostURL()}/api/v1/users/signup`, body);
}

//Dashboard API's
export function getDraftInvoiceAPI() {
  return axios.get(`${getHostURL()}/api/v1/manager/dashboard/getDraftInvoice`);
}
export function getPendingInvoiceAPI() {
  return axios.get(
    `${getHostURL()}/api/v1/manager/dashboard/getPendingInvoice`
  );
}
export function getUnpaidInvoiceAPI() {
  return axios.get(`${getHostURL()}/api/v1/manager/dashboard/getUnpaidInvoice`);
}
export function getFullyPaidInvoiceAPI() {
  return axios.get(
    `${getHostURL()}/api/v1/manager/dashboard/getFullyPaidInvoice`
  );
}
export function getPartiallyPaidInvoiceAPI() {
  return axios.get(
    `${getHostURL()}/api/v1/manager/dashboard/getPartiallyPaidInvoice`
  );
}
export function getVoidInvoiceAPI() {
  return axios.get(`${getHostURL()}/api/v1/manager/dashboard/getVoidInvoice`);
}

//Monthly Budget Controller

export function getMonthlyBudget(year, month, body, page, size,order) {
  return axios.post(
    `${getHostURL()}/api/v1/user/budget/${year}/${month}?filter=ALL&order=${order}&sort=PROJECTNAME&page=${page}&size=${size}`,
    body
  );
}
export function getTotalMonthlyProjection(year,month,body){
   return axios.post(
    `${getHostURL()}/api/v1/user/budget/totalMonthlyProjection/${year}/${month}`,
    body
   );
}
export function getProjectionSummary(year){
  return axios.get(
    `${getHostURL()}/api/v1/user/budget/projectionSummary/${year}`
  );
}
export function getAllUnmappedTransactions(id) {
  return axios.get(
    `${getHostURL()}/api/v1/account/unmappedPaymentDetail/${id}`
  );
}
export function deleteUnMappedTransactions(id) {
  return axios.delete(
    `${getHostURL()}/api/v1/account/deletePaymentByMapStatusId/${id}`
  );
}
export function addMontlyBudget(body) {
  return axios.post(`${getHostURL()}/api/v1/user/budget/addBudget`, body);
}
export function updateMonthlyBudget(body) {
  return axios.post(`${getHostURL()}/api/v1/user/budget/updateBudgets`, body);
}
export function getAllPendingInvoices(body, sort, order, page, size) {
  return axios.post(
    `${getHostURL()}/api/v1/user/invoice/list/unpaidInvoices?filter=ALL&sort=${sort}&order=${order}&page=${page}&size=${size}`,
    body
  );
}
export function getTransactionFile(body) {
  return axios.post(
    `${getHostURL()}/api/v1/account/statementFile/import`,
    body
  );
}
export function getUploadedFileStatus(id) {
  return axios.get(`${getHostURL()}/api/v1/account/checkStatus?id=${id}`);
}
export function getFileData(id, sortOrder, sortBy) {
  return axios.get(
    `${getHostURL()}/api/v1/account/paymentDetails/${id}?order=${sortOrder}&sort=${sortBy}`
  );
}
export function saveImportedFile(id) {
  return axios.post(
    `${getHostURL()}/api/v1/account/paymentDetail/save?fileId=${id}`
  );
}
export function updateInvoiceDetail(id, body) {
  return axios.post(
    `${getHostURL()}/api/v1/account/paymentInvoiceRelation/add?arPaymentDetailId=${id}`,
    body
  );
}
export function getPaymentDetailByID(id) {
  return axios.get(
    `${getHostURL()}/api/v1/account/paymentDetailsById?id=${id}`
  );
}
export function getUploadError(id) {
  return axios.get(`${getHostURL()}/api/v1/account/errors?id=${id}`);
}
export function getUploadedFileList() {
  return axios.get(`${getHostURL()}/api/v1/account/getAllStatementFiles`);
}
export function updateInvoiceStatus(body) {
  return axios.post(`${getHostURL()}/api/v1/account/updatePaymentStatus`, body);
}
export function processARInvoice(body) {
  return axios.post(`${getHostURL()}/api/v1/account/processARInvoice`, body);
}
export function getMappedTransaction(id) {
  return axios.get(
    `${getHostURL()}/api/v1/user/invoice/getAllPaymentRelationByInvoiceId?id=${id}`
  );
}
export function getFullyPaidDetails(id) {
  return axios.post(
    `${getHostURL()}/api/v1/user/invoice/getAmountDueDetail?invoiceId=${id}`
  );
}
export function updateUserDetails(id, body) {
  return axios.put(
    `${getHostURL()}/api/v1/manageUsers/updateRoleAndStatus/${id}`,
    body
  );
}
export function updateUserContactNo(id, body) {
  return axios.put(`${getHostURL()}/api/v1/user/UpdateContactNo/${id}`, body);
}
export function getUserByID(id) {
  return axios.get(`${getHostURL()}/api/v1/user/getUserById/${id}`);
}
export function deleteBudgetId(id) {
  return axios.delete(`${getHostURL()}/api/v1/user/budget/deleteBudget/${id}`);
}
export function createBudgetWithZero(year, month) {
  return axios.get(
    `${getHostURL()}/api/v1/user/budget/createBudgetWithZero/${year}/${month}`
  );
}
export function updateInvoicePaymentStatus(id, body) {
  return axios.put(
    `${getHostURL()}/api/v1/user/invoice/UpdateInvoicePaymentStatus/${id}`,
    body
  );
}
export function updateMultipleInvoicePaymentStatus(body) {
  return axios.put(
    `${getHostURL()}/api/v1/user/invoice/updateMultipleInvoicePaymentStatus`,
    body
  );
}
export function updateMultipleInvoiceStatus(body) {
  return axios.put(
    `${getHostURL()}/api/v1/user/invoice/updateMultipleInvoiceStatus`,
    body
  );
}
export function budgetValidation(body) {
  const request = {
    password: body,
  };
  return axios.post(
    `${getHostURL()}/api/v1/user/budget/validateBudgetPassword`,
    request
  ); 
}
export const getProjectPurchaseOrdersAPI = (projectId) => {
  return axios.get(`${getHostURL()}/api/v1/user/project/${projectId}/purchaseOrders`);
};

export function updateWeeklyInvoiceDesc(body) {
  return axios.post(
    `${getHostURL()}/api/v1/user/upwork-invoice/create`,
    body
  );
}