import React, { useRef, useState, useEffect, useContext } from "react";
import "../../assets/styles/addcontent.css";
import { responseData } from "./viewProject";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import { addProjectAPI, updateProjectAPI } from "../../utils/api-helper";
import Select from "react-select";
import { MdOutlineCancel } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import {
  validateLongTextWithSPace,
  validateCharacterLimitNormalTextField,
  validateNumber,
} from "../../utils/input-validation";
import {
  requiredclientNameErrorMessage,
  requiredProjectMangerErrorMessage,
  requiredProjectNameErrorMessage,
  requiredProjectStartDateErrorMessage,
  ProjectNameErrorMessage,
  requiredProjectTypeErrorMessage,
  requiredProjectBudgetErrorMessage,
  validateCharacterLimitNormalTextFieldErrorMessage,
  requiredDeliveryMangerErrorMessage,
  requiredIsUpworkErrorMessage,
  requiredRateErrorMessage
} from "../../utils/status-message-helper";
import { useLocation } from "react-router-dom";
import { selectStatus, billableStatusOptions } from "../../utils/constants";
import DatePicker from "../../components/DatePicker";

export default function CreateNewClient(props) {
  const [clientId, setClientId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectManagerId, setprojectManagerId] = useState("");
  const [deliveryManagerId, setDeliveryManagerId] = useState("");
  const [locationId, setLocationId] = useState(1);
  const [projectStartDate, setProjectStartDate] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Active");
  const [isActive, setIsActive] = useState(true);
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [purchaseOrderArray, setPurchaseOrderArray] = useState([]);
  const [clientNameErrMsg, setClientNameErrMsg] = useState("");
  const [projectMangerErrMsg, setProjectMangerErrMsg] = useState("");
  const [deliveryManagerErrMsg, setDeliverManagerErrMsg] = useState("");
  const [projectNameErrMsg, setProjectNameErrMsg] = useState("");
  const [purchaseOrderErrMsg, setPurchaseOrderErrMsg] = useState("");
  const [projectStartDateErrMsg, setProjectStartDateErrMsg] = useState("");
  const [revenueGenerating, setRevenueGenerating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isEnforceProjectBudget, setIsEnforceProjectBudget] = useState(false);

  const [projectBudget, setProjectBudget] = useState("");
  const [projectBudgetErrMsg, setProjectBudgetErrMsg] = useState("");
  const [projectTypeId, setProjectTypeId] = useState("");
  const [projectTypeErrMsg, setprojectTypeErrMsg] = useState("");
  const [isUpWorkErrMsg, setisUpWorkErrMsg] = useState('');
  const [rate, setRate] = useState();
  const [createProjectForm, setCreateProjectForm] = useState(true);

  const [isUpWorkProject, setIsUpWorkProject] = useState(null);
  const [rateErrMsg,setRateErrMsg] = useState("");
  var search = useLocation().search;
  useEffect(() => {
    if (props.isEditProject) {
      setCreateProjectForm(false);
      setEditProjectData();
    } else {
      document.getElementById("setActiveStatus").isDisabled = true;
    }
  }, [props.isEditProject]);

  const checkProjectLocationStatus = () => {
    let check = false;
    if (clientId && clientId != "") {
      props.getAllClientsType.filter((item, index) => {
        if (
          clientId === item.value &&
          (item.label == "L" || item.label == "I")
        ) {
          check = true;
        }
      });
    }
    return check;
  };
  const setEditProjectData = () => {
    setClientId(responseData.clientId);
    setProjectName(responseData.projectName);
    setprojectManagerId(responseData.projectManagerId);
    setDeliveryManagerId(responseData.deliveryManagerId);
    setLocationId(responseData.projectLocationId);
    setProjectStartDate(responseData.startDate);
    setDescription(responseData.description);
    setProjectTypeId(responseData.projectTypeId);
    setPurchaseOrderArray(responseData.poNumbers);
    setProjectBudget(responseData.projectBudget);
    setIsEnforceProjectBudget(responseData.isProjectBudgetRequire);
    setRevenueGenerating(responseData.revenueGenerating);
    setIsActive(responseData.isActive);
    setIsUpWorkProject(responseData.isUpWorkProject);
    setRate(responseData.rate)
  };

  const handleCreateProject = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (status == "Inactive") {
        setIsActive(false);
      } else {
        setIsActive(true);
      }
      const updatedPurchaseOrderArray = [...purchaseOrderArray];
      if (purchaseOrder.trim() !== "") {
        updatedPurchaseOrderArray.push(purchaseOrder.trim());
      }
      const body = {
        clientId: clientId,
        description: description,
        isActive: true,
        projectManagerId: projectManagerId,
        deliveryManagerId: deliveryManagerId,
        projectName: projectName,
        projectBudget: Number(projectBudget),
        startDate: projectStartDate,
        projectType: projectTypeId,
        poNumbers: updatedPurchaseOrderArray,
        projectLocationId: locationId,
        revenueGenerating: revenueGenerating,
        isProjectBudgetRequire: isEnforceProjectBudget ? true : false,
        isUpWorkProject: isUpWorkProject,
        rate: rate
      };
      props.setStartLoader(true);
      props.setAlertReponse(false);
      props.setAlertPopupWindow({ is: false, type: "check" });
      addProjectAPI(body)
        .then((res) => {
          setSuccess(true);
          props.getAllProjects();
          props.setStartLoader(false);
        })
        .catch((err) => {
          setSuccess(false);
          props.setAlertPopupMessage(err.response?.data.message);
          props.setAlertPopupWindow({ is: true, type: "alert" });
          props.setStartLoader(false);
        });
    }
  };

  const handleEditProject = (e) => {
    e.preventDefault();
    var id = new URLSearchParams(search).get("id");
    if (validateForm()) {
      const updatedPurchaseOrderArray = [...purchaseOrderArray];
      if (purchaseOrder.trim() !== "") {
        updatedPurchaseOrderArray.push(purchaseOrder.trim());
      }
      const body = {
        id: id,
        clientId: clientId,
        description: description,
        isActive: isActive,
        projectManagerId: projectManagerId,
        deliveryManagerId: deliveryManagerId,
        projectName: projectName,
        projectBudget: Number(projectBudget),
        projectType: projectTypeId,
        startDate: projectStartDate,
        poNumbers: updatedPurchaseOrderArray,
        projectLocationId: locationId,
        revenueGenerating: revenueGenerating,
        isProjectBudgetRequire: isEnforceProjectBudget ? true : false,
        isUpWorkProject: isUpWorkProject,
        rate : rate
      };
      props.setStartLoader(true);
      props.setAlertReponse(false);
      props.setAlertPopupWindow({ is: false, type: "check" });
      updateProjectAPI(body)
        .then((res) => {
          setSuccess(true);
          props.getAllProjects();
          props.setStartLoader(false);
        })
        .catch((err) => {
          setSuccess(false);
          props.setAlertPopupMessage(err.response?.data.message);
          props.setAlertPopupWindow({ is: true, type: "alert" });
          props.setStartLoader(false);
        });
    }
  };

  function checkClientName() {
    if (clientId) {
      setClientNameErrMsg("");
      return true;
    } else {
      setClientNameErrMsg(requiredclientNameErrorMessage);
      return false;
    }
  }
  function checkProjectManagerName() {
    if (projectManagerId) {
      setProjectMangerErrMsg("");
      return true;
    } else {
      setProjectMangerErrMsg(requiredProjectMangerErrorMessage);
      return false;
    }
  }
  function checkDeliveryManagerName() {
    if (deliveryManagerId) {
      setDeliverManagerErrMsg("");
      return true;
    } else {
      setDeliverManagerErrMsg(requiredDeliveryMangerErrorMessage);
      return false;
    }
  }
  function checkProjectType() {
    if (projectTypeId) {
      setprojectTypeErrMsg("");
      return true;
    } else {
      setprojectTypeErrMsg(requiredProjectTypeErrorMessage);
      return false;
    }
  }

  function checkIsUpworkProject() {

    if (isUpWorkProject !== null) {
      setisUpWorkErrMsg('');
      return true;
    } else if (isUpWorkProject === null || isUpWorkProject === undefined) {
      setisUpWorkErrMsg(requiredIsUpworkErrorMessage);
      return false;
    }
  }

  function checkProjectName() {
    if (projectName) {
      if (validateCharacterLimitNormalTextField(projectName)) {
        setProjectNameErrMsg("");
        return true;
      } else {
        setProjectNameErrMsg(validateCharacterLimitNormalTextFieldErrorMessage);
        return false;
      }
    } else {
      setProjectNameErrMsg(requiredProjectNameErrorMessage);
      return false;
    }
  }

  function checkProjectBudget() {
    if (projectBudget) {
      if (validateNumber(projectBudget)) {
        setProjectBudgetErrMsg("");
        return true;
      } else {
        setProjectBudgetErrMsg(requiredProjectBudgetErrorMessage);
        return false;
      }
    }
  }
  function checkProjectStartDate() {
    if (projectStartDate) {
      setProjectStartDateErrMsg("");
      return true;
    } else {
      setProjectStartDateErrMsg(requiredProjectStartDateErrorMessage);
      return false;
    }
  }

  function checkRate(){
    if(rate){
      if(validateNumber(rate)){
        setRateErrMsg("");
        return true
      }else{
        setRateErrMsg(requiredRateErrorMessage)
        return false
      }
    }else{
      if(isUpWorkProject){
        setRateErrMsg(requiredRateErrorMessage)
        return false
      }
      return true
    }
  }

  function validateForm() {
    let isValid = true;
    if (
      checkClientName() &&
      checkProjectManagerName() &&
      checkDeliveryManagerName() &&
      checkProjectName() &&
      checkProjectStartDate() &&
      checkProjectType() &&
      checkIsUpworkProject() &&
      checkRate()
    ) {
      isValid = true;
    } else {
      checkClientName();
      checkProjectManagerName();
      checkDeliveryManagerName();
      checkProjectName();
      checkProjectStartDate();
      checkIsUpworkProject();
      checkRate();
      isValid = false;
    }
    return isValid;
  }
  const maxDateLimit = new Date("3000-12-01");
  const callApiAfterApproval = () => {
    if (createProjectForm) {
      handleCreateProject();
    } else {
      handleEditProject();
    }
  };
  useEffect(() => {
    if (props.getAlertReponse) {
      callApiAfterApproval();
      props.setAlertReponse(false);
      props.setAlertPopupWindow({ is: false, type: "check" });
    }
  }, [props.getAlertReponse]);

  const handleCheckBox = () => {
    setIsEnforceProjectBudget(!isEnforceProjectBudget);
  };
  const handleRemovePurchaseOrder = (value) => {
    setPurchaseOrderArray(prev => prev.filter(ele => ele.trim() !== value.trim()));
  }

  return (
    <div className='inner-content-formData project'>
      <form
        className='form'
        method='POST'
        id='AddClient'
        onSubmit={createProjectForm ? handleCreateProject : handleEditProject}
      >
        <div className='form-container-project'>
          <div className='flex-form-container'>
            <div className='projectName form_input'>
              <div className='inputLabel left-flex'>
                <label>
                  Project Name<span className='errmsg'>*</span>:
                </label>
              </div>
              <input
                type='text'
                id='projectName'
                placeholder='Project Name'
                name='projectName'
                onChange={(e) => setProjectName(e.target.value)}
                value={projectName}
              />
              <div className='form_message form_message-error left-flex'>
                <p
                  className={projectNameErrMsg ? "errmsg" : "offscreen"}
                  aria-live='assertive'
                >
                  {projectNameErrMsg}
                </p>
              </div>
            </div>
            <div className='projectManger form_input'>
              <div className='inputLabel left-flex'>
                <label>
                  Project Manager<span className='errmsg'>*</span>:
                </label>
              </div>
              <Select
                type='text'
                id='projectManger'
                styles={{ width: "100%", float: "left" }}
                placeholder={
                  <div className='select-placeholder-text'>Project Manager</div>
                }
                defaultValue={
                  props.isEditProject
                    ? {
                      label:
                        responseData.projectManagerFirstName +
                        " " +
                        responseData.projectManagerLastName,
                      value: responseData.projectManagerId,
                    }
                    : ""
                }
                name='projectManger'
                options={props.getSortedProjectManager}
                isClearable={true}
                onChange={(e) => {
                  if (!e) {
                    e = { value: "" };
                  }
                  setprojectManagerId(e.value);
                }}
              />
              <div className='form_message form_message-error left-flex'>
                <p
                  className={projectMangerErrMsg ? "errmsg" : "offscreen"}
                  aria-live='assertive'
                >
                  {projectMangerErrMsg}
                </p>
              </div>
            </div>
            <div className='projectManger form_input'>
              <div className='inputLabel left-flex'>
                <label>
                  Delivery Manager<span className='errmsg'>*</span>:
                </label>
              </div>
              <Select
                type='text'
                id='deliveryManger'
                styles={{ width: "100%", float: "left" }}
                placeholder={
                  <div className='select-placeholder-text'>
                    Delivery Manager
                  </div>
                }
                defaultValue={
                  props.isEditProject
                    ? {
                      label:
                        responseData.deliveryManagerFirstName +
                        " " +
                        responseData.deliveryManagerLastName,
                      value: responseData.deliveryManagerId,
                    }
                    : ""
                }
                name='deliveryManger'
                options={props.getSortedDeliveryManager}
                isClearable={true}
                onChange={(e) => {
                  if (!e) {
                    e = { value: "" };
                  }
                  setDeliveryManagerId(e.value);
                }}
              />
              <div className='form_message form_message-error left-flex'>
                <p
                  className={deliveryManagerErrMsg ? "errmsg" : "offscreen"}
                  aria-live='assertive'
                >
                  {deliveryManagerErrMsg}
                </p>
              </div>
            </div>
            <div className='clientName form_input' style={{ width: "100%" }}>
              <div className='inputLabel left-flex'>
                <label>
                  Client Name<span className='errmsg'>*</span>:
                </label>
              </div>
              <Select
                type='text'
                id='clientName'
                styles={{ width: "100%", float: "left" }}
                placeholder={
                  <div className='select-placeholder-text'>Client Name</div>
                }
                defaultValue={
                  props.isEditProject
                    ? {
                      label:
                        responseData.clientFirstName +
                        " " +
                        responseData.clientLastName,
                      value: responseData.clientId,
                    }
                    : ""
                }
                name='clientId'
                options={props.getAllClients}
                isClearable={true}
                onChange={(e) => {
                  if (!e) {
                    e = { value: "" };
                  }
                  setClientId(e.value);
                }}
              />
              <div className='form_message form_message-error left-flex'>
                <p
                  className={clientNameErrMsg ? "errmsg" : "offscreen"}
                  aria-live='assertive'
                >
                  {clientNameErrMsg}
                </p>
              </div>
            </div>
            <div className='projectManger form_input'>
              <div className='inputLabel left-flex'>
                <label>Project Location:</label>
              </div>
              <Select
                type='text'
                id='invoiceAddress'
                styles={{ width: "100%", float: "left" }}
                placeholder={
                  <div className='select-placeholder-text'>
                    Project Execution Location
                  </div>
                }
                defaultValue={
                  props.isEditProject
                    ? {
                      label: responseData.projectArea
                        ? responseData.projectArea +
                        " | " +
                        responseData.projectCity
                        : "No Location Available",
                      value: responseData.projectLocationId,
                    }
                    : { value: 1, label: "Sai Shilp Business Center | Pune" }
                }
                name='invoiceAddress'
                options={props.getInvoiceLocationOrigin}
                isClearable={true}
                onChange={(e) => {
                  if (!e) {
                    e = { value: "" };
                  }
                  setLocationId(e.value);
                }}
              // isDisabled={checkProjectLocationStatus()}
              />
              {/* <div className="form_message form_message-error left-flex">
                                <p
                                    className={projectMangerErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {projectMangerErrMsg}
                                </p>
                            </div> */}
            </div>
            <div className='projectManger form_input'>
              <div className='inputLabel left-flex'>
                <label>Project Type</label><span className='errmsg'>*</span>:
              </div>
              <Select
                type='text'
                id='projectManger'
                styles={{
                  ...props.selectStyles, // Spread the other default styles
                  width : '100%', float : 'left', 
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: isUpWorkProject ? 'var(--navyblue1)' : baseStyles.borderColor, // Change border color conditionally
                    borderWidth : isUpWorkProject ? '3px' : baseStyles.borderWidth
                  }),
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    zIndex: 9999, // Ensure the dropdown appears correctly
                  }),
                }}
                placeholder={
                  projectTypeId ? (
                    projectTypeId
                  ) : (
                    <div className='select-placeholder-text'>
                      Select Project Type
                    </div>
                  )
                }
                defaultValue={
                  props.isEditProject
                    ? {
                      value: responseData.projectTypeId,
                      label: responseData.projectType,
                    }
                    : ""
                }
                name='projectType'
                options={props.getProjectType}
                isClearable={true}
                onChange={(e) => {
                  if (!e) {
                    e = { value: "" };
                  }
                  setProjectTypeId(e.value);
                }}
              />
              <div className='form_message form_message-error left-flex'>
                <p
                  className={projectTypeErrMsg ? "errmsg" : "offscreen"}
                  aria-live='assertive'
                >
                  {projectTypeErrMsg}
                </p>
              </div>
            </div>

            <div className='projectManger form_input'>
              <div className='inputLabel left-flex'>
                <label>Upwork Project</label><span className='errmsg'>*</span>:
              </div>
              <Select
                type='text'
                id='projectManger'
                styles={{ width: "100%", float: "left" }}
                placeholder={
                  isUpWorkProject !== null ? (
                    isUpWorkProject
                  ) : (
                    <div className='select-placeholder-text'>
                      Is Upwork Project?
                    </div>
                  )
                }
                defaultValue={
                  props.isEditProject
                    ? {
                      value: responseData.isUpWorkProject ? "Yes" : "No",
                      label: responseData.isUpWorkProject ? "Yes" : "No",
                    }
                    : ''
                }
                name='isUpWorkProject'
                options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                // isClearable={true}
                onChange={(e) => {
                  if (!e) {
                    e = { value: "" };
                  }
                  setIsUpWorkProject(e.value)
                }}
              />
              <div className='form_message form_message-error left-flex'>
                {
                  isUpWorkErrMsg ? <p
                    // className={isUpWorkErrMsg ? "errmsg" : "offscreen"}
                    aria-live='assertive'
                  >
                    {requiredIsUpworkErrorMessage}
                  </p> : null
                }

              </div>
            </div>


            <div className='checkbox form_input'>
              <FormControlLabel
                sx={{ marginLeft: "auto" }}
                control={
                  <Checkbox
                    sx={{ padding: "0px" }}
                    onChange={(e) => handleCheckBox(e)}
                    checked={isEnforceProjectBudget}
                  />
                }
                label='&nbsp; enforce project budget when creating new invoices.'
              />
            </div>
          </div>
          <div className='flex-form-container'>
            <div className='projectName form_input'>
              <div className='inputLabel left-flex'>
                <label>{"Project Budget (Optional)"}</label>
                <span className='errmsg'>
                  {isEnforceProjectBudget ? "*" : ""}{" "}
                </span>
                :
              </div>
              <input
                type='number'
                id='projectBudget'
                placeholder='Project Budget'
                name='projectBudget'
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => setProjectBudget(e.target.value)}
                value={projectBudget}
              />
              <div className='form_message form_message-error left-flex'>
                <p
                  className={projectBudgetErrMsg ? "errmsg" : "offscreen"}
                  aria-live='assertive'
                >
                  {projectBudgetErrMsg}
                </p>
              </div>
            </div>
            <div className='projectStartDate form_input'>
              <div className='inputLabel left-flex'>
                <label>
                  Project Start Date<span className='errmsg'>*</span>:
                </label>
              </div>
              <div className='datePickerInput'>
                <DatePicker
                  date={projectStartDate}
                  setDate={setProjectStartDate}
                />
              </div>
              <div className='form_message form_message-error left-flex'>
                <p
                  className={projectStartDateErrMsg ? "errmsg" : "offscreen"}
                  aria-live='assertive'
                >
                  {projectStartDateErrMsg}
                </p>
              </div>
            </div>
            <div className='purchaseOrder form_input'>
              <div className='inputLabel left-flex' >
                <label> Purchase Order </label>
                <div
                  style={{ fontSize: "16px", margin: '0 20px' }}
                  onClick={() => {
                    setPurchaseOrderArray((prev) => {
                      if (prev.length == 0) {
                        return [purchaseOrder];
                      }
                      else {
                        const newArray = prev.filter(item => item !== purchaseOrder);
                        return [...newArray, purchaseOrder];
                      }

                    })
                    setPurchaseOrder("");
                  }}><IoIosAdd /></div>
              </div>
              <input
                type='text'
                id='purchaseOrder'
                placeholder='Purchase Order'
                name='purchaseOrder'
                onChange={(e) => setPurchaseOrder((e.target.value))}
                value={purchaseOrder}
              />
              {Array.isArray(purchaseOrderArray) && purchaseOrderArray.length > 0 ?

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {purchaseOrderArray.map((ele, index) => (
                    <div key={index} style={{ padding: "8px", boxShadow: "2px 2px 1px 1px", display: "flex", borderRadius: "5px", margin: "4px", border: "1px solid black", justifyContent: "space-between", alignItems: "center", width: "fit-content", gap: "5px" }} >{ele} <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }} onClick={() => handleRemovePurchaseOrder(ele)}><MdOutlineCancel /></span></div>))}
                </div>
                : <></>
              }
              <div className='form_message form_message-error left-flex'>
                <p
                  className={purchaseOrderErrMsg ? "errmsg" : "offscreen"}
                  aria-live='assertive'
                >
                  {purchaseOrderErrMsg}
                </p>
              </div>
            </div>
            <div className='status form_input'>
              <div className='inputLabel left-flex'>
                <label>Status:</label>
              </div>
              <Select
                type='text'
                id='setActiveStatus'
                styles={{ width: "100%", float: "left" }}
                placeholder={
                  props.isEditProject ? (
                    <div className='select-placeholder-text'>Select Status</div>
                  ) : (
                    "Active"
                  )
                }
                defaultValue={
                  props.isEditProject
                    ? {
                      label: responseData.isActive ? "Active" : "Inactive",
                      value: responseData.isActive ? "Active" : "Inactive",
                    }
                    : ""
                }
                name='status'
                options={selectStatus}
                onChange={(e) => setIsActive(e.value)}
                isDisabled={props.isEditProject ? false : true}
              />
            </div>
            <div className='status form_input'>
              <div className='inputLabel left-flex'>
                <label>Revenue Generating:</label>
              </div>
              <Select
                type='text'
                id='setBillableStatus'
                styles={{ width: "100%", float: "left" }}
                placeholder={
                  (responseData.revenueGenerating === undefined || responseData.revenueGenerating === null)
                    ? "Select Status"
                    : responseData.revenueGenerating ? "True" : "False"
                }
                defaultValue={
                  (responseData.revenueGenerating === undefined || responseData.revenueGenerating === null)
                    ? ""
                    : {
                      label: responseData.revenueGenerating ? "True" : "False",
                      value: responseData.revenueGenerating ? "True" : "False",
                    }
                }
                name='billableStatus'
                options={billableStatusOptions}
                onChange={(e) => setRevenueGenerating(e.value)}
              />
            </div>

            <div className='status form_input'>
              <div className='inputLabel left-flex'>
                <label>Rate:</label>
              </div>
              <input
                type='number'
                id='rate'
                placeholder='Rate'
                name='rate'
                onChange={(e) => setRate(e.target.value)}
                value={rate}
                style={{
                  borderColor: isUpWorkProject ? 'var(--navyblue1)' : 'initial', // Conditionally change the border color
                  outlineColor: isUpWorkProject ? 'var(--navyblue1)' : 'initial', // Optional: Change the outline color as well
                  borderWidth : isUpWorkProject && '3px'  
                }}
              />

              
                <div className='form_message form_message-error left-flex'>
                  <p aria-live='assertive'>{rateErrMsg}</p>
                </div>
              
            </div>



            <div className='description form_input'>
              <div className='inputLabel left-flex'>
                <label>Description:</label>
              </div>
              <textarea
                type='text'
                name='description'
                style={{ resize: "none" }}
                id='description'
                placeholder='Description...'
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>
            <button className='saveButton right-flex'>SAVE</button>
          </div>
        </div>
      </form>
    </div>
  );
}
